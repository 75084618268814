.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.message-box strong {
  font-size: 17px;
}
.exlamation-img {
  background-color: #f64b1cb3;
  width: 80px;
  padding: 15px !important;
}
.exlamation-img svg {
  width: 100%;
}
.btn-message {
  background-color: #f64b1c !important;
  color: white !important;
  border: 1px solid #f64b1c !important;
  transition: all ease-in-out 0.7s;
}
.btn-message:hover {
  background-color: transparent !important;
  color: #f64b1c !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* modal css */
.modal-header-heading h4 {
  font-size: 24px;
  font-weight: bold;
  color: #181819a1;
  display: inline-block;
  border-bottom: 3px solid #f64b1c;
}
.airdropmodal-wrapper .modal-footer,
.airdropmodal-wrapper .modal-header {
  border: unset;
}
.airmodal-content-wrapper {
  margin-bottom: 10px;
}
.airmodal-content .dropdown-toggle,
.airmodal-content .dropdown-menu.show,
.airmodal-content select {
  width: 100%;
  text-align: left;
}
.airmodal-content .dropdown-menu.show {
  border: 2px solid #181819a1;
}
.airmodal-content .dropdown-divider {
  border-top: 1px solid #181819a1;
}
.airmodal-content .dropdown-toggle::after {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}
.airmodal-content h6,
.airmodal-content form label {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #000;
}
.airmodal-content form .form-control {
  border: 1px solid #8080806b;
  padding: 22px 15px;
}
.info-icon {
  color: white;
}
.airmodal-content form .form-control:focus {
  border: 1px solid #8080806b;
  box-shadow: unset;
}
.airdropmodal-boxe {
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.airdropmodal-boxe-content {
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.airdropmodal-boxe-heading {
  background: #181819a1;
  padding: 10px 13px;
  text-align: center;
}
.airdropmodal-boxe-heading h4 {
  color: white;
}
.airdropmodal-boxe-content p {
  font-size: 14px;
  color: black;
  font-weight: normal;
}
.airdropmodal-boxe-content p span {
  font-size: 16px;
  color: #181819a1;
  font-weight: bold;
}
.btn-gray {
  background-color: gray !important;
  border-color: gray !important;
}
.btn-black {
  background-color: #0000007a !important;
  border-color: #0000007a !important;
}
.btn-black .plus-icon {
  color: white;
}
@media only screen and (max-width: 767px) {
  .btn-gray {
    background-color: gray !important;
    border-color: gray !important;
    padding: 14px 12px !important;
    font-size: 14px !important;
  }
  form {
    width: 100% !important;
  }
}
@media only screen and (max-width: 575px) {
  .modal-header-heading h4 {
    font-size: 20px;
  }
  .airmodal-content h6,
  .airmodal-content form label {
    font-size: 15px;
  }
}

.selected-icosetting {
  border: 1px solid !important;
  background: #ffa4a291 !important;
}

.user-dropdown-menu {
  height: 300px;
  overflow: scroll;
}

.buytoken-connect button {
  padding: 10px 7px;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1em;
  border-radius: 4px !important;
  text-transform: uppercase;
}
