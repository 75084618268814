@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-Bold.eot");
  src: local("Poppins Bold"), local("Poppins-Bold"),
    url("../fonts/Poppins-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Poppins-Bold.woff2") format("woff2"),
    url("../fonts/Poppins-Bold.woff") format("woff"),
    url("../fonts/Poppins-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-Light.eot");
  src: local("Poppins Light"), local("Poppins-Light"),
    url("../fonts/Poppins-Light.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Poppins-Light.woff2") format("woff2"),
    url("../fonts/Poppins-Light.woff") format("woff"),
    url("../fonts/Poppins-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-SemiBold.eot");
  src: local("Poppins SemiBold"), local("Poppins-SemiBold"),
    url("../fonts/Poppins-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Poppins-SemiBold.woff2") format("woff2"),
    url("../fonts/Poppins-SemiBold.woff") format("woff"),
    url("../fonts/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-Medium.eot");
  src: local("Poppins Medium"), local("Poppins-Medium"),
    url("../fonts/Poppins-Medium.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Poppins-Medium.woff2") format("woff2"),
    url("../fonts/Poppins-Medium.woff") format("woff"),
    url("../fonts/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-Regular.eot");
  src: local("Poppins Regular"), local("Poppins-Regular"),
    url("../fonts/Poppins-Regular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Poppins-Regular.woff2") format("woff2"),
    url("../fonts/Poppins-Regular.woff") format("woff"),
    url("../fonts/Poppins-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
/* Default Scroll Bar Styles */
#style-default::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #c1c1c1;
  border-radius: 10px;
  background-color: #f5f5f5;
}
/* Scroll Bar Styles */
* {
  scrollbar-width: thin;
  /* border-radius:5px; */
  scrollbar-color: #f64b1c #ccc;
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border-radius: 5px;
}
::-webkit-scrollbar-track {
  background: #ffe4e6;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb {
  background-color: #f64b1c;
  border-radius: 5px;
}
[data-rk] .ju367v13 {
  font-size: 12px !important;
  line-height: 18px !important;
}
[data-rk] .ju367va {
  display: flex;
  flex-wrap: wrap !important;
}
[data-rk] .iekbcc0.ju367v4.ju367va.ju367v2h.ju367v2l {
  height: auto;
  margin-bottom: 16px;
}
/* bootstrap-css */
.max-100 {
  max-width: 100% !important;
}
/* DESKTOP-HOME */
.cpn-desktop {
  display: block;
}
.desktop-copyrights {
  display: block;
  width: 528px;
}
/* MOBILE-HOME */
.cpn-mobile,
.mobile-copyrights,
.mobile-icon {
  display: none;
}
/* BANNER */
/* BANNER */
.banner-social-mobile {
  display: none;
}
.banner-social-desktop {
  display: block;
}
/*  */
.text-black-theme {
  color: #304073;
}
.on {
  font-size: 15px !important;
  border-radius: 5px;
  padding: 7px 10px !important;
}
.orange-email > div {
  padding: 18px 0px !important;
}
.orange-email > div > img {
  width: 115px;
  margin-top: 20px;
}
.kyc-upload-img img {
  margin: 0 5px;
}
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
}
.filter-loader {
  height: 48px;
  max-width: 190px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* .radio-btn svg{
  width: 24px;
  height: 24px;
} */
.sale-line-chart {
  width: 100%;
  height: 250px;
}
.size-error svg {
  width: 21px;
  height: 21px;
}
.bg-white {
  background: #fff !important;
}
#style-default::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}
::-ms-reveal {
  display: none !important;
}
.w-md-45 {
  width: 45%;
}
.modal-scroll-y.modal-height {
  padding: 0px 13px;
}
.modal-scroll {
  overflow-y: auto;
}
.blue-span {
  font-size: 12px !important;
  line-height: 15px !important;
  font-weight: 400 !important;
  color: #6783b8 !important;
}
/* .investor-modal .modal-content{
  padding:10px;
} */
[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none !important;
  appearance: none;
  height: 10px !important;
  width: 10px !important;
  /* background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE2LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgd2lkdGg9IjEyMy4wNXB4IiBoZWlnaHQ9IjEyMy4wNXB4IiB2aWV3Qm94PSIwIDAgMTIzLjA1IDEyMy4wNSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTIzLjA1IDEyMy4wNTsiDQoJIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGc+DQoJPHBhdGggZD0iTTEyMS4zMjUsMTAuOTI1bC04LjUtOC4zOTljLTIuMy0yLjMtNi4xLTIuMy04LjUsMGwtNDIuNCw0Mi4zOTlMMTguNzI2LDEuNzI2Yy0yLjMwMS0yLjMwMS02LjEwMS0yLjMwMS04LjUsMGwtOC41LDguNQ0KCQljLTIuMzAxLDIuMy0yLjMwMSw2LjEsMCw4LjVsNDMuMSw0My4xbC00Mi4zLDQyLjVjLTIuMywyLjMtMi4zLDYuMSwwLDguNWw4LjUsOC41YzIuMywyLjMsNi4xLDIuMyw4LjUsMGw0Mi4zOTktNDIuNGw0Mi40LDQyLjQNCgkJYzIuMywyLjMsNi4xLDIuMyw4LjUsMGw4LjUtOC41YzIuMy0yLjMsMi4zLTYuMSwwLTguNWwtNDIuNS00Mi40bDQyLjQtNDIuMzk5QzEyMy42MjUsMTcuMTI1LDEyMy42MjUsMTMuMzI1LDEyMS4zMjUsMTAuOTI1eiIvPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPC9zdmc+DQo=); */
  background-size: 10px 10px !important;
  background-image: url(../../../public/images/payment-icons/search-close.svg) !important;
}
.search-w {
  width: 300px;
  justify-content: flex-end;
}
.search-mx {
  width: 100%;
}
form.user-ath-form{
  width: auto ;
}
.passwordInput{
  position: relative;
}
.passwordInput .passwordIcon {
  position: absolute;
  right: 10px;
  top: 8px;
}
.search-wrap,
.wrap-search-input {
  width: 100% !important;
}
.menu-ul ul {
  padding-bottom: 0px !important;
}
/* .tag-label  */
.icon-inside-input {
  margin-right: -19px;
  z-index: 222;
  position: relative;
}
.icon-inside-input2 {
  position: absolute;
  left: 10px;
}
.min-coin-logo{
  width: 10%;
}
.text-dark {
  color: #000 !important;
}
.email-templates-variables h3 {color: #000;}
.email-templates-variables .col-md-3,.email-templates-variables .col-md-6{border-left: 1px solid #ccc;margin-left: 5px;}
.email-templates-variables h3 {position: relative;}
.email-templates-variables h3::before{content: ''; position: absolute; background-color: #ccc; height: 1px; width: 290px; bottom: -2px;left: -12px;}
.email-templates-variables code{font-weight: bolder;}
.email-templates-variables span small{font-weight: 600;}
.email-formating{padding: 15px;}
.email-formating h4{color:#181819;}
.email-formating ul li{border: 1px solid #9a9a9a; padding: 8px 12px; border-radius: 7px; margin: 0 3px; display: flex; align-items: center;}
.email-formating ul li:first-child{margin-left: 0;}
.email-formating ul li:last-child{margin-right: 0;}
.email-formating ul li span{font-size: 14px;}
#style-default::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(68, 63, 63, 0.3);
  background-color: #555;
}
input.placeholder-color::placeholder,
.header-search-wrapper-sub-div input::placeholder,
.header-search-wrapper-sub-div input:-ms-input-placeholder {
  color: #fff !important;
}
.address-btn {
  width: 100px;
  height: 40px;
  margin-bottom: 2px;
}
.dropdown-flex-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.pagination-css ul li button {
  color: #181819a1;
}
.bg-primary {
  background-color: #f64b1c !important;
}
.multicolor-btn button {
  padding: 2px;
}
.bitcoin-modal{ overflow-y: hidden !important;}
.bitcoin-modal .modal-content {
  max-width: 100%;
  height: 94vh;
  overflow-y: auto;
  /* height:400px; */
}
.bitcoin-modal .bitcoin-form {
  /* overflow-y: scroll; */
}
.ico-label label {
  width: auto !important;
}
label {
  color: #181819a1;
}
.email-form header,
.table-kyc-modal header,
.table-view-modal header,
.table-kyc-view header {
  background-color: #6d6d6e !important;
  width: 70% !important;
}
.email-msg-modal > div {
  height: 550px !important;
}
.inner-form-kyc-view {
  height: 550px !important;
}
.inner-form-kyc-view > div,
.email-msg-modal > div {
  width: 70% !important;
}
.table-kyc-container .scrollbar-container {
  background-color: #fff;
}
.bg-gd-dusk {
  background: #f64b1c linear-gradient(135deg, #f64b1c, #f64b1c) !important;
}
.form-bg {
  background-color: #fff;
}
.amount-center > div {
  display: flex;
  align-items: center;
}
.del-btn {
  /* background: #ffe4e6; */
  padding: 6px 14px;
  border-radius: 20px;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f64b1c;
  transition: all ease-in-out 0.4s;
}
.mt-btn {
  margin-top: 37px;
}
.del-btn:hover {
  box-shadow: 0 2px 18px 2px rgb(43 86 245 / 25%);
}
.cms_cards {
  display: flex;
}
.modal-height {
  /* height: 480px; */
  overflow-x: hidden;
  overflow-y: auto;
}
.note-info p.error-color {
  color: tomato !important;
}
.error-color {
  color: tomato !important;
}
/* .about-pt{
  padding-top:50px;
} */
.overspill-inherit {
  overflow: inherit !important;
}
.timeline-w-75 {
  width: 75%;
}
.tiny-tuple-text {
  font-weight: 600 !important;
  text-transform: capitalize !important;
}
.contact-us-form.home-contact-form {
  background-color: transparent;
  box-shadow: none;
}
.user-header-h5 {
  font-size: 20px;
  line-height: 26px;
  color: #fff !important;
  font-weight: 600;
  margin: 16px 16px 10px 16px;
}
.btn-primary:not(:disabled):not(.disabled):active:focus {
  background: #2f4858;
}
.upload-btn2.btn span {
  font-size: 12px;
  line-height: 24px;
}
.upload-btn2 svg {
  font-size: 14px;
}
.upload-btn2 svg:hover {
  color: #fff;
}
.tab-upload-btn .bg-primary {
  font-size: 16px;
  line-height: 24px;
  padding: 4px 13px;
  min-width: 120px;
  background-image: linear-gradient(90deg, #fe4350 0, #ff6c3f);
  border-radius: 45px !important;
}
.tab-upload-btn .bg-primary .d-flex {
  align-items: center;
}
.tab-upload-btn .bg-primary svg {
  font-size: 14px;
}
.tab-upload-btn svg:hover {
  color: #fff;
}
.elipse {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100px;
}
.z-index {
  position: relative;
  z-index: 9;
}
.shadow-none {
  box-shadow: none !important;
}
.text-inherit {
  text-transform: inherit !important;
}
.min-auto {
  min-width: auto;
}
.payment-page {
  color: white;
  padding: 30px;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 63%, 0 98%);
  clip-path: polygon(0 0, 100% 0, 100% 63%, 0 98%);
  background: linear-gradient(to right, #ff6c3f 0%, #fe4350 100%) !important;
}
.payment-page h2 {
  margin-bottom: 0;
  font-size: 36px;
  line-height: 45px;
}
.transction-btc-btn {
  background: linear-gradient(to right, #ff6c3f 0%, #fe4350 100%) !important;
}
.amount-style {
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: 2px solid #d3e0f3;
  width: 100%;
  line-height: 20px;
  font-size: 14px;
  color: #6783b8;
  transition: all 0.4s;
}
.amount-style .payment-cal-cur {
  position: initial;
  /* min-width: 105px; */
  text-align: end;
}
.elipse {
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
header .menu-link.nav-link {
  color: #415076;
}
header .menu-link.nav-link:hover {
  color: #f64b1c;
}
.badge-without-number {
  position: absolute;
  background-color: #f5424e;
  font-size: 11px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  top: 9px;
  right: -8px;
}

.badge-without-number.with-wave {
  animation-name: wave;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes wave {
  0% {box-shadow: 0 0 0px 0px rgba(245, 66, 78, 0.5);}
  100% {box-shadow: 0 0 0px 10px rgba(245, 66, 78, 0);}
}

@keyframes changingShape {
  0% {transform:rotate(0);border-radius: 0;}
  50% {transform:rotate(360deg);border-radius: 50%;}
  100% {transform:rotate(720deg);border-radius: 0;}
}
/*  */
.icnBoxhide {
  display: none;
}
.icnBox {
  left: 100px;
  position: absolute;
  width: 300px;
  top: -64px;
  z-index: 222222;
  background-image: linear-gradient(90deg, #fe4350e8 0, #ff6c3fe6) !important;
  border-radius: 5px;
  box-shadow: #d3e0f37d 0px 54px 55px, rgb(0 0 0 / 12%) 0px -12px 30px, rgb(0 0 0 / 12%) 0px 4px 6px, rgb(0 0 0 / 17%) 0px 12px 13px, rgb(0 0 0 / 9%) 0px -3px 5px;
}
.msg-icn {
  display: inline-block;
  position: relative;
  padding:  15px 10px 10px 10px;
  color: #fff;
  box-sizing: border-box;
  max-width: 300px;
  min-width: 80px;
  min-height: 38px;
  font-size: 12.5px;
}
.msg-icn:before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 5px;
  box-sizing: border-box;
  clip-path: polygon(0% 105%, 0% 0%, 105% 0%, 105% 105%, 43px 105%, 43px 80%, 21px 80%, 21px 105%);
}
.msg-icn:after {
  content: "";
  position: absolute;
  display: block;
  width: 25px;
  height: 20px;
  background: #fd555e;
  top: calc(100% - 5px);
  left: 20px;
  box-sizing: border-box;
  clip-path: polygon(0 0, 0% 100%, 100% 0%, calc(100% - 5px) 0, 1px calc(100% - 21px), 1px 0);
}
.icnCircle {
  position: absolute;
  right: 0;
  display: block;
  top: 2px;
  float: right;
}
.icnCircle .fa {
  font-size: 14px !important;
  color: #fff !important;
}
/* Message box starts here */
.msgBox {
  clear: both;
  position: relative;
}

.msgBox .arrow {
  width: 12px;
  height: 20px;
  overflow: hidden;
  position: relative;
  float: left;
  top: 6px;
  right: -1px;
}

.msgBox .arrow .outer {
  width: 0;
  height: 0;
  border-right: 20px solid #000000;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  position: absolute;
  top: 0;
  left: 0;
}

.msgBox .arrow .inner {
  width: 0;
  height: 0;
  border-right: 20px solid #ffffff;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  position: absolute;
  top: 0;
  left: 2px;
}

.msgBox .message-body {
  float: left;
  width: 300px;
  height: auto;
  border: 1px solid #CCC;
  background-color: #ffffff;
  border: 1px solid #000000;
  padding: 6px 8px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
}

.msgBox .message-body p {
  margin: 0;
}

.add-user-css [type="button"] {
  height: 30px !important;
  min-width: 30px !important;
  border-radius: 5px;
  transition: all ease-in-out 0.4s;
}
.btn-neutral svg {
  font-size: 16px;
}
.btn-neutral:hover {
  background-color: #fff !important;
  color: #f64b1c !important;
}
.toggleBtn{
  display: none;
}
.side-bars {
  color: #fff;
}
.custom-icon {
  color: #fff !important;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all ease-in-out 0.4s;
}
.custom-icon:hover {
  opacity: 0.5;
  outline: none;
  transform: translateY(-3px);
  background: none !important;
}
.css-1kuq5xv-MuiButtonBase-root-MuiIconButton-root:hover {
  background: none !important;
}
.search-box .MuiInput-underline:before {
  border-bottom: 1px solid #fff !important;
}
.small-input input[type="text"],
.kyc-small-input .small-input input[type="text"] {
  padding: 7.5px 4px 7.5px 6px;
}
.country-slector .css-1s2u09g-control {border: 2px solid #d3e0f3; min-height: 44px;}
.table td.table-btn-group div,
table td.text-left div {
  gap: 6px;
  box-shadow: none;
  color: #fff;
}
.table td.table-btn-group div [type="button"],
table td.text-left div [type="button"] {
  border: 0px;
  border-radius: 5px;
}
.data-table.tax-table
  thead
  th:not(:first-child):not(:first-child):not(:last-child) {
  min-width: 110px;
}
.data-table.tax-table thead th {
  min-width: 50px;
}
.dashboard-menu .css-b5f33u-MuiButtonBase-root-MuiMenuItem-root:hover {
  background: transparent;
  color: #f64b1c;
}
.password-form .w95 {
  width: 75%;
}
#add-user-header {
  padding: 5px 0px 10px 0px;
}
.custom-tab {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}
.custom-tab .MuiList-padding {
  background-image: linear-gradient(
    135deg,
    #fff 0%,
    #fff 100%
  ) !important;
  background: #fff !important ;
  border: 1px solid #f64b1c;
}
.custom-tab .MuiListItem-root {
  padding: 18px;
  color: #f64b1c;
  background-color: #fff;
  border: 1px solid #fe5748;
  justify-content: center;
}
.MuiListItem-button:hover{ 
  color: #fff;
}
.css-1e6y48t-MuiButtonBase-root-MuiButton-root:hover {
  background: transparent;
}
.modal-form {
  padding-top: 40px;
  background: #fff;
}
.makeStyles-paper60-2 {
  height: 630px;
  background-color: transparent !important;
}
.wrap-search-input input::placeholder,
.wrap-search-input .MuiInputBase-input {
  color: #fff;
}
.wrap-search-input .MuiInput-underline:before {
  border-color: #fff;
}
.wrap-search-input .MuiInput-underline:hover:not(.Mui-disabled):before {
  border-color: #fff;
}
.btn-shadow-none .css-zqcytf-MuiButtonGroup-root {
  box-shadow: none !important;
}
.bg-grey .bg-success {
  background-color: #f64b1c !important;
}
.bg-grey .bg-success:hover,
.bg-grey .bg-success:focus {
  background-color: #ffe4e6 !important;
}
.bg-grey .bg-success:hover span,
.bg-grey .bg-success:focus span {
  color: #f64b1c !important;
}
.bg-grey .bg-primary-dark-op {
  background-color: #1facd8 !important;
}
.bg-grey .bg-primary-dark-op:hover,
.bg-grey .bg-primary-dark-op:focus {
  background-color: #1facd81f !important;
}
.bg-grey .bg-primary-dark-op:hover span,
.bg-grey .bg-primary-dark-op:focus span {
  color: #1facd8 !important;
}
.bg-grey .bg-warning {
  background-color: #a4a296 !important;
}
.bg-grey .bg-warning:hover,
.bg-grey .bg-warning:focus {
  background-color: #b5b5b563 !important;
}
.bg-grey .bg-warning:hover span,
.bg-grey .bg-warning:focus span {
  color: #757471 !important;
}
.bg-grey .bg-danger {
  background-color: #fe4350 !important;
}
.bg-grey .bg-danger:hover,
.bg-grey .bg-danger:focus {
  background-color: #fe4350a6 !important;
}
.bg-grey .bg-danger:hover span,
.bg-grey .bg-danger:focus span {
  color: #fff !important;
}
.MuiList-padding .MuiMenuItem-root {
  padding: 7px 50px !important;
  font-size: 14px;
}
.dropdown-flex-col a,
.dropdown-flex-col .MuiMenuItem-root {
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.logo-list-img {
  width: 50px;
  height: 50px;
}
.logo-list-img img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.theme-color {
  color: #F64B1C;
  font-family: Poppins, sans-serif;
  font-weight: 500;
}
a {
  transition: all ease-in-out 0.4s;
}
a.text-primary:hover {
  color: #0d6efd !important;
  border-bottom: 1px solid #0d6efd;
  transform: scale3d(1.06, 1.06, 1);
}
.topbar-action-item img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.transaction-search input:focus {
  box-shadow: unset !important;
}
.accordion-item:not(:last-child) {
  border-bottom: 0 !important;
}
.avater-container {
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 20px;
  box-shadow: 0px 0px 12px 0px #e3cfcf;
}

.avatar-img-contain {
  border-radius: 50%;
  border-color: #f64b1c;
}
.avatar-img-contain img {
  font-size: 12px;
}
.topbar-action-list > div > button{
  background: #f64b1c !important;
}
.connect-wallet > div > button:first-child {
  border: none !important;
  padding: 9px !important;
  color: white !important;
  background: #f64b1c !important;
  border: 2px solid transparent !important;
  box-shadow: rgb(0 0 0 / 22%) 1px 2px 5px !important;
  border-radius: 6px !important;
  font-size: 13px !important;
  /* margin-right: 10px !important; */
  /* height: 40px !important; */
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all ease-in-out 0.4s !important;
}
.connect-wallet > div > button:first-child:hover {
  color: #ff6542 !important;
  border: 2px solid #ff6542 !important;
  background: transparent !important;
}
.avater-img > img {
  border-radius: 50%;
  width: 80px;
  height: 80px;
}
.kyc-upload-img img {
  width: 200px;
  height: 200px;
  /* border-radius: 50%; */
}
.d-color {
  color: #f64b1c;
}
.d-c-width {
  width: 25px;
  font-size: 20px;
}
/*! 02.0 - RESET */
body {
  font-family: "Poppins", sans-serif;
  color: #3c3c3c;
  font-size: 14px;
  line-height: 1.86;
  font-weight: 400;
  letter-spacing: .25px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  background: #efebec;
  min-width: 320px;
  /* color: #6783b8; */
}
.sidebar-inner-btn {
  border: 2px solid #f64b1c !important;
  margin: 10px auto;
  display: flex;
  color: #000;
  width: fit-content;
  justify-content: center;
  transition: all ease-in-out 0.4s;
}
.sidebar-inner-btn:hover {
  color: #fff;
  background-color: #f64b1c !important;
}
.user-wraper {
  padding-top: 120px;
}

.user-content-main {
  width: 100%;
  margin-bottom: 50px;
  overflow: hidden;
}

img {
  max-width: 100%;
}

.error {
  color: red;
  font-size: 14px;
  font-weight: 400;
}
.error span {
  color: tomato;
}
.error1 {
  color: red;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
}

.kycstatus-btn {
  width: 110px;
}
.kycstatus-btn .MuiButton-label {
  color: #fff !important;
}
.kyc-action .MuiButton-contained {
  box-shadow: unset !important;
  min-width: 130px;
}
.block-title span {
  font-size: 24px;
  font-weight: 600;
  padding-bottom: 5px;
  /* color: #fff; */
}
.block-header .block-title span {
  color: #fff;
}

#manage-users-modal {
  padding-top: 18px;
}
#manage-users-modal #settings-header .MuiListItem-root.Mui-selected {
  background-color: #fe5748;
  border: 1px solid #fe5748;
  color: #fff;
  background-image: linear-gradient(
    135deg,
    #ff6c3f 0%,
    #fe4350 100%
  ) !important;
}
.tranx-from span {
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.account-updatr-btn {
  z-index: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul:not(.btn-grp),
ol,
table {
  margin-bottom: 12px;
  line-height: 1.68;
}

h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child,
p:last-child,
ul:not(.btn-grp):last-child,
ol:last-child,
table:last-child {
  margin-bottom: 0;
}

h1,
.h1 {
  font-size: 1.75em;
}

h2,
.h2 {
  font-size: 1.58em;
}

h3,
.h3 {
  font-size: 1.32em;
}

h4,
.h4 {
  font-size: 1.2em;
}

h5,
.h5 {
  font-size: 1em;
}

h6,
.h6 {
  font-size: 0.9em;
}

p {
  font-size: 1em;
}

p.lead {
  font-size: 1.27em;
}

h1 span,
h2 span,
h3 span,
h4 span,
h5 span,
h6 span,
p span {
  color: #f64b1c;
}

ul,
ol {
  padding: 0px;
  margin: 0px;
}

ul li,
ol li {
  list-style: none;
}

.relative {
  position: relative;
}

p + h1,
p + h2,
p + h3,
p + h4,
p + h5,
p + h4,
ul + h1,
ul + h2,
ul + h3,
ul + h4,
ul + h5,
ul + h4,
ol + h1,
ol + h2,
ol + h3,
ol + h4,
ol + h5,
ol + h4,
table + h1,
table + h2,
table + h3,
table + h4,
table + h5,
table + h4 {
  margin-top: 35px;
}

ul + p,
ul + ul,
ul + ol,
ol + ol,
ol + ul,
ul + table,
ol + table {
  margin-top: 35px;
}

b,
strong {
  font-weight: 600;
}

a {
  outline: 0;
  transition: all 0.5s;
  color: #2f4858;
}

a:link,
a:visited {
  text-decoration: none;
}

a:hover,
a:focus,
a:active {
  outline: 0;
  color: #f64b1c;
}

.container {
  z-index: 5;
}

/*! 03.0 - UTILITY */
.top-gap {
  margin-top: 80px;
}
.ucap {
  text-transform: uppercase;
}

.cap {
  text-transform: capitalize;
}

.pad-0,
.nopd {
  padding: 0px;
}

.npl {
  padding-left: 0px;
}

.npr {
  padding-right: 0px;
}

.overflow-x-auto {
  overflow-x: auto;
}

.sap {
  width: 100%;
  height: 1px;
  margin: 20px 0;
  background: #edf5ff;
}

.gaps-1x {
  height: 10px;
}

.gaps-2x {
  height: 20px;
}

.gaps-3x {
  height: 30px;
}

.gaps-4x {
  height: 40px;
}

.gaps-5x {
  height: 50px;
}

.gaps-6x {
  height: 60px;
}

.gaps-7x {
  height: 70px;
}

.gaps-8x {
  height: 80px;
}

.gaps-9x {
  height: 90px;
}

.gaps-10x {
  height: 100px;
}

.gaps-11x {
  height: 110px;
}

.gaps-12x {
  height: 120px;
}

.gaps-13x {
  height: 130px;
}

.gaps-14x {
  height: 140px;
}

.gaps-15x {
  height: 150px;
}

.gaps-16x {
  height: 160px;
}

.gaps-17x {
  height: 170px;
}

.gaps-18x {
  height: 180px;
}

.gaps-19x {
  height: 190px;
}

.gaps-20x {
  height: 200px;
}

.padl-1x {
  padding-left: 10px;
}

.padl-2x {
  padding-left: 20px;
}

.padl-3x {
  padding-left: 30px;
}

.padl-4x {
  padding-left: 40px;
}

.padl-5x {
  padding-left: 50px;
}

.padl-6x {
  padding-left: 60px;
}

.padl-7x {
  padding-left: 70px;
}

.padl-8x {
  padding-left: 80px;
}

.padl-9x {
  padding-left: 90px;
}

.padl-10x {
  padding-left: 100px;
}

.padl-11x {
  padding-left: 110px;
}

.padl-12x {
  padding-left: 120px;
}

.padl-13x {
  padding-left: 130px;
}

.padl-14x {
  padding-left: 140px;
}

.padl-15x {
  padding-left: 150px;
}

.padl-16x {
  padding-left: 160px;
}

.padl-17x {
  padding-left: 170px;
}

.padl-18x {
  padding-left: 180px;
}

.padl-19x {
  padding-left: 190px;
}

.padl-20x {
  padding-left: 200px;
}

.w1 {
  width: 1%;
}

.w2 {
  width: 2%;
}

.w3 {
  width: 3%;
}

.w4 {
  width: 4%;
}

.w5 {
  width: 5%;
}

.w6 {
  width: 6%;
}

.w7 {
  width: 7%;
}

.w8 {
  width: 8%;
}

.w9 {
  width: 9%;
}

.w10 {
  width: 10%;
}

.w11 {
  width: 11%;
}

.w12 {
  width: 12%;
}

.w13 {
  width: 13%;
}

.w14 {
  width: 14%;
}

.w15 {
  width: 15%;
}

.w16 {
  width: 16%;
}

.w17 {
  width: 17%;
}

.w18 {
  width: 18%;
}

.w19 {
  width: 19%;
}

.w20 {
  width: 20%;
}

.w21 {
  width: 21%;
}

.w22 {
  width: 22%;
}

.w23 {
  width: 23%;
}

.w24 {
  width: 24%;
}

.w25 {
  width: 25%;
}

.w26 {
  width: 26%;
}

.w27 {
  width: 27%;
}

.w28 {
  width: 28%;
}

.w29 {
  width: 29%;
}

.w30 {
  width: 30%;
}

.w31 {
  width: 31%;
}

.w32 {
  width: 32%;
}

.w33 {
  width: 33%;
}

.w34 {
  width: 34%;
}

.w35 {
  width: 35%;
}

.w36 {
  width: 36%;
}

.w37 {
  width: 37%;
}

.w38 {
  width: 38%;
}

.w39 {
  width: 39%;
}

.w40 {
  width: 40%;
}

.w41 {
  width: 41%;
}

.w42 {
  width: 42%;
}

.w43 {
  width: 43%;
}

.w44 {
  width: 44%;
}

.w45 {
  width: 45%;
}
.w-45 {
  width: 45%;
}
.ico-input-w45 {
  width: 45%;
}
.w46 {
  width: 46%;
}

.w47 {
  width: 47%;
}

.w48 {
  width: 48%;
}

.w49 {
  width: 49%;
}

.w50 {
  width: 50%;
}

.w51 {
  width: 51%;
}

.w52 {
  width: 52%;
}

.w53 {
  width: 53%;
}

.w54 {
  width: 54%;
}

.w55 {
  width: 55%;
}

.w56 {
  width: 56%;
}

.w57 {
  width: 57%;
}

.w58 {
  width: 58%;
}

.w59 {
  width: 59%;
}

.w60 {
  width: 60%;
}

.w61 {
  width: 61%;
}

.w62 {
  width: 62%;
}

.w63 {
  width: 63%;
}

.w64 {
  width: 64%;
}

.w65 {
  width: 65%;
}

.w66 {
  width: 66%;
}

.w67 {
  width: 67%;
}

.w68 {
  width: 68%;
}

.w69 {
  width: 69%;
}

.w70 {
  width: 70%;
}

.w71 {
  width: 71%;
}

.w72 {
  width: 72%;
}

.w73 {
  width: 73%;
}

.w74 {
  width: 74%;
}

.w75 {
  width: 75%;
}

.w76 {
  width: 76%;
}

.w77 {
  width: 77%;
}

.w78 {
  width: 78%;
}

.w79 {
  width: 79%;
}

.w80 {
  width: 80%;
}

.w81 {
  width: 81%;
}

.w82 {
  width: 82%;
}

.w83 {
  width: 83%;
}

.w84 {
  width: 84%;
}

.w85 {
  width: 85%;
}

.w86 {
  width: 86%;
}

.w87 {
  width: 87%;
}

.w88 {
  width: 88%;
}

.w89 {
  width: 89%;
}

.w90 {
  width: 90%;
}

.w91 {
  width: 91%;
}

.w92 {
  width: 92%;
}

.w93 {
  width: 93%;
}

.w94 {
  width: 94%;
}

.w95 {
  width: 95%;
  margin-top: 60px !important;
}
.w97-4 {
  width: 97.4%;
  margin-top: 60px !important;
}
.w96 {
  width: 96%;
}

.w97 {
  width: 97%;
}
/* .w97-4 { width: 97.4%; } */

.w98 {
  width: 98%;
}

.w99 {
  width: 99%;
}

.w100 {
  width: 100%;
}

.w1px {
  width: 1px;
}

.w2px {
  width: 2px;
}

.w3px {
  width: 3px;
}

.w4px {
  width: 4px;
}

.w5px {
  width: 5px;
}

.w6px {
  width: 6px;
}

.w7px {
  width: 7px;
}

.w8px {
  width: 8px;
}

.w9px {
  width: 9px;
}

.w10px {
  width: 10px;
}

.w11px {
  width: 11px;
}

.w12px {
  width: 12px;
}

.w13px {
  width: 13px;
}

.w14px {
  width: 14px;
}

.w15px {
  width: 15px;
}

.w16px {
  width: 16px;
}

.w17px {
  width: 17px;
}

.w18px {
  width: 18px;
}

.w19px {
  width: 19px;
}

.w20px {
  width: 20px;
}

.w21px {
  width: 21px;
}

.w22px {
  width: 22px;
}

.w23px {
  width: 23px;
}

.w24px {
  width: 24px;
}

.w25px {
  width: 25px;
}

.w26px {
  width: 26px;
}

.w27px {
  width: 27px;
}

.w28px {
  width: 28px;
}

.w29px {
  width: 29px;
}

.w30px {
  width: 30px;
}

.w31px {
  width: 31px;
}

.w32px {
  width: 32px;
}

.w33px {
  width: 33px;
}

.w34px {
  width: 34px;
}

.w35px {
  width: 35px;
}

.w36px {
  width: 36px;
}

.w37px {
  width: 37px;
}

.w38px {
  width: 38px;
}

.w39px {
  width: 39px;
}

.w40px {
  width: 40px;
}

.w41px {
  width: 41px;
}

.w42px {
  width: 42px;
}

.w43px {
  width: 43px;
}

.w44px {
  width: 44px;
}

.w45px {
  width: 45px;
}

.w46px {
  width: 46px;
}

.w47px {
  width: 47px;
}

.w48px {
  width: 48px;
}

.w49px {
  width: 49px;
}

.w50px {
  width: 50px;
}

.w51px {
  width: 51px;
}

.w52px {
  width: 52px;
}

.w53px {
  width: 53px;
}

.w54px {
  width: 54px;
}

.w55px {
  width: 55px;
}

.w56px {
  width: 56px;
}

.w57px {
  width: 57px;
}

.w58px {
  width: 58px;
}

.w59px {
  width: 59px;
}

.w60px {
  width: 60px;
}

.w61px {
  width: 61px;
}

.w62px {
  width: 62px;
}

.w63px {
  width: 63px;
}

.w64px {
  width: 64px;
}

.w65px {
  width: 65px;
}

.w66px {
  width: 66px;
}

.w67px {
  width: 67px;
}

.w68px {
  width: 68px;
}

.w69px {
  width: 69px;
}

.w70px {
  width: 70px;
}

.w71px {
  width: 71px;
}

.w72px {
  width: 72px;
}

.w73px {
  width: 73px;
}

.w74px {
  width: 74px;
}

.w75px {
  width: 75px;
}

.w76px {
  width: 76px;
}

.w77px {
  width: 77px;
}

.w78px {
  width: 78px;
}

.w79px {
  width: 79px;
}

.w80px {
  width: 80px;
}

.w81px {
  width: 81px;
}

.w82px {
  width: 82px;
}

.w83px {
  width: 83px;
}

.w84px {
  width: 84px;
}

.w85px {
  width: 85px;
}

.w86px {
  width: 86px;
}

.w87px {
  width: 87px;
}

.w88px {
  width: 88px;
}

.w89px {
  width: 89px;
}

.w90px {
  width: 90px;
}

.w91px {
  width: 91px;
}

.w92px {
  width: 92px;
}

.w93px {
  width: 93px;
}

.w94px {
  width: 94px;
}

.w95px {
  width: 95px;
}

.w96px {
  width: 96px;
}

.w97px {
  width: 97px;
}

.w98px {
  width: 98px;
}

.w99px {
  width: 99px;
}

.w100px {
  width: 100px;
}

.wauto {
  width: auto;
}

.hauto {
  height: auto;
}

.vh100 {
  height: 100vh;
}

.guttar-1px {
  margin-left: -0.5px;
  margin-right: -0.5px;
}

.guttar-1px > li,
.guttar-1px > div {
  padding-left: 0.5px;
  padding-right: 0.5px;
}

.guttar-2px {
  margin-left: -1px;
  margin-right: -1px;
}

.guttar-2px > li,
.guttar-2px > div {
  padding-left: 1px;
  padding-right: 1px;
}

.guttar-3px {
  margin-left: -1.5px;
  margin-right: -1.5px;
}

.guttar-3px > li,
.guttar-3px > div {
  padding-left: 1.5px;
  padding-right: 1.5px;
}

.guttar-4px {
  margin-left: -2px;
  margin-right: -2px;
}

.guttar-4px > li,
.guttar-4px > div {
  padding-left: 2px;
  padding-right: 2px;
}

.guttar-5px {
  margin-left: -2.5px;
  margin-right: -2.5px;
}

.guttar-5px > li,
.guttar-5px > div {
  padding-left: 2.5px;
  padding-right: 2.5px;
}

.guttar-6px {
  margin-left: -3px;
  margin-right: -3px;
}

.guttar-6px > li,
.guttar-6px > div {
  padding-left: 3px;
  padding-right: 3px;
}

.guttar-7px {
  margin-left: -3.5px;
  margin-right: -3.5px;
}

.guttar-7px > li,
.guttar-7px > div {
  padding-left: 3.5px;
  padding-right: 3.5px;
}

.guttar-8px {
  margin-left: -4px;
  margin-right: -4px;
}

.guttar-8px > li,
.guttar-8px > div {
  padding-left: 4px;
  padding-right: 4px;
}

.guttar-9px {
  margin-left: -4.5px;
  margin-right: -4.5px;
}

.guttar-9px > li,
.guttar-9px > div {
  padding-left: 4.5px;
  padding-right: 4.5px;
}

.guttar-10px {
  margin-left: -5px;
  margin-right: -5px;
}

.guttar-10px > li,
.guttar-10px > div {
  padding-left: 5px;
  padding-right: 5px;
}

.guttar-11px {
  margin-left: -5.5px;
  margin-right: -5.5px;
}

.guttar-11px > li,
.guttar-11px > div {
  padding-left: 5.5px;
  padding-right: 5.5px;
}

.guttar-12px {
  margin-left: -6px;
  margin-right: -6px;
}

.guttar-12px > li,
.guttar-12px > div {
  padding-left: 6px;
  padding-right: 6px;
}

.guttar-13px {
  margin-left: -6.5px;
  margin-right: -6.5px;
}

.guttar-13px > li,
.guttar-13px > div {
  padding-left: 6.5px;
  padding-right: 6.5px;
}

.guttar-14px {
  margin-left: -7px;
  margin-right: -7px;
}

.guttar-14px > li,
.guttar-14px > div {
  padding-left: 7px;
  padding-right: 7px;
}

.guttar-15px {
  margin-left: -7.5px;
  margin-right: -7.5px;
}

.guttar-15px > li,
.guttar-15px > div {
  padding-left: 7.5px;
  padding-right: 7.5px;
}

.guttar-16px {
  margin-left: -8px;
  margin-right: -8px;
}

.guttar-16px > li,
.guttar-16px > div {
  padding-left: 8px;
  padding-right: 8px;
}

.guttar-17px {
  margin-left: -8.5px;
  margin-right: -8.5px;
}

.guttar-17px > li,
.guttar-17px > div {
  padding-left: 8.5px;
  padding-right: 8.5px;
}

.guttar-18px {
  margin-left: -9px;
  margin-right: -9px;
}

.guttar-18px > li,
.guttar-18px > div {
  padding-left: 9px;
  padding-right: 9px;
}

.guttar-19px {
  margin-left: -9.5px;
  margin-right: -9.5px;
}

.guttar-19px > li,
.guttar-19px > div {
  padding-left: 9.5px;
  padding-right: 9.5px;
}

.guttar-20px {
  margin-left: -10px;
  margin-right: -10px;
}

.guttar-20px > li,
.guttar-20px > div {
  padding-left: 10px;
  padding-right: 10px;
}

.guttar-21px {
  margin-left: -10.5px;
  margin-right: -10.5px;
}

.guttar-21px > li,
.guttar-21px > div {
  padding-left: 10.5px;
  padding-right: 10.5px;
}

.guttar-22px {
  margin-left: -11px;
  margin-right: -11px;
}

.guttar-22px > li,
.guttar-22px > div {
  padding-left: 11px;
  padding-right: 11px;
}

.guttar-23px {
  margin-left: -11.5px;
  margin-right: -11.5px;
}

.guttar-23px > li,
.guttar-23px > div {
  padding-left: 11.5px;
  padding-right: 11.5px;
}

.guttar-24px {
  margin-left: -12px;
  margin-right: -12px;
}

.guttar-24px > li,
.guttar-24px > div {
  padding-left: 12px;
  padding-right: 12px;
}

.guttar-25px {
  margin-left: -12.5px;
  margin-right: -12.5px;
}

.guttar-25px > li,
.guttar-25px > div {
  padding-left: 12.5px;
  padding-right: 12.5px;
}

.guttar-26px {
  margin-left: -13px;
  margin-right: -13px;
}

.guttar-26px > li,
.guttar-26px > div {
  padding-left: 13px;
  padding-right: 13px;
}

.guttar-27px {
  margin-left: -13.5px;
  margin-right: -13.5px;
}

.guttar-27px > li,
.guttar-27px > div {
  padding-left: 13.5px;
  padding-right: 13.5px;
}

.guttar-28px {
  margin-left: -14px;
  margin-right: -14px;
}

.guttar-28px > li,
.guttar-28px > div {
  padding-left: 14px;
  padding-right: 14px;
}

.guttar-29px {
  margin-left: -14.5px;
  margin-right: -14.5px;
}

.guttar-29px > li,
.guttar-29px > div {
  padding-left: 14.5px;
  padding-right: 14.5px;
}

.guttar-30px {
  margin-left: -15px;
  margin-right: -15px;
}

.guttar-30px > li,
.guttar-30px > div {
  padding-left: 15px;
  padding-right: 15px;
}

.guttar-31px {
  margin-left: -15.5px;
  margin-right: -15.5px;
}

.guttar-31px > li,
.guttar-31px > div {
  padding-left: 15.5px;
  padding-right: 15.5px;
}

.guttar-32px {
  margin-left: -16px;
  margin-right: -16px;
}

.guttar-32px > li,
.guttar-32px > div {
  padding-left: 16px;
  padding-right: 16px;
}

.guttar-33px {
  margin-left: -16.5px;
  margin-right: -16.5px;
}

.guttar-33px > li,
.guttar-33px > div {
  padding-left: 16.5px;
  padding-right: 16.5px;
}

.guttar-34px {
  margin-left: -17px;
  margin-right: -17px;
}

.guttar-34px > li,
.guttar-34px > div {
  padding-left: 17px;
  padding-right: 17px;
}

.guttar-35px {
  margin-left: -17.5px;
  margin-right: -17.5px;
}

.guttar-35px > li,
.guttar-35px > div {
  padding-left: 17.5px;
  padding-right: 17.5px;
}

.guttar-36px {
  margin-left: -18px;
  margin-right: -18px;
}

.guttar-36px > li,
.guttar-36px > div {
  padding-left: 18px;
  padding-right: 18px;
}

.guttar-37px {
  margin-left: -18.5px;
  margin-right: -18.5px;
}

.guttar-37px > li,
.guttar-37px > div {
  padding-left: 18.5px;
  padding-right: 18.5px;
}

.guttar-38px {
  margin-left: -19px;
  margin-right: -19px;
}

.guttar-38px > li,
.guttar-38px > div {
  padding-left: 19px;
  padding-right: 19px;
}

.guttar-39px {
  margin-left: -19.5px;
  margin-right: -19.5px;
}

.guttar-39px > li,
.guttar-39px > div {
  padding-left: 19.5px;
  padding-right: 19.5px;
}

.guttar-40px {
  margin-left: -20px;
  margin-right: -20px;
}

.guttar-40px > li,
.guttar-40px > div {
  padding-left: 20px;
  padding-right: 20px;
}

.guttar-41px {
  margin-left: -20.5px;
  margin-right: -20.5px;
}

.guttar-41px > li,
.guttar-41px > div {
  padding-left: 20.5px;
  padding-right: 20.5px;
}

.guttar-42px {
  margin-left: -21px;
  margin-right: -21px;
}

.guttar-42px > li,
.guttar-42px > div {
  padding-left: 21px;
  padding-right: 21px;
}

.guttar-43px {
  margin-left: -21.5px;
  margin-right: -21.5px;
}

.guttar-43px > li,
.guttar-43px > div {
  padding-left: 21.5px;
  padding-right: 21.5px;
}

.guttar-44px {
  margin-left: -22px;
  margin-right: -22px;
}

.guttar-44px > li,
.guttar-44px > div {
  padding-left: 22px;
  padding-right: 22px;
}

.guttar-45px {
  margin-left: -22.5px;
  margin-right: -22.5px;
}

.guttar-45px > li,
.guttar-45px > div {
  padding-left: 22.5px;
  padding-right: 22.5px;
}

.guttar-46px {
  margin-left: -23px;
  margin-right: -23px;
}

.guttar-46px > li,
.guttar-46px > div {
  padding-left: 23px;
  padding-right: 23px;
}

.guttar-47px {
  margin-left: -23.5px;
  margin-right: -23.5px;
}

.guttar-47px > li,
.guttar-47px > div {
  padding-left: 23.5px;
  padding-right: 23.5px;
}

.guttar-48px {
  margin-left: -24px;
  margin-right: -24px;
}

.guttar-48px > li,
.guttar-48px > div {
  padding-left: 24px;
  padding-right: 24px;
}

.guttar-49px {
  margin-left: -24.5px;
  margin-right: -24.5px;
}

.guttar-49px > li,
.guttar-49px > div {
  padding-left: 24.5px;
  padding-right: 24.5px;
}

.guttar-50px {
  margin-left: -25px;
  margin-right: -25px;
}

.guttar-50px > li,
.guttar-50px > div {
  padding-left: 25px;
  padding-right: 25px;
}

/** Button **/
.btn {
  position: relative;
  color: #fff;
  padding: 10px 30px;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1em;
  border-radius: 4px;
  text-transform: uppercase;
  transition: all 0.4s ease;
}

.btn:hover,
.btn:focus,
.btn:active {
  box-shadow: none;
  outline: none;
}

.btn .ti,
.btn [class*="fa-"] {
  margin-right: 8px;
}

.btn-icon {
  letter-spacing: 0.01em;
}

.btn-sm {
  padding: 10px 18px;
  line-height: 19px;
}

.btn-xs {
  padding: 5px 12px;
  font-size: 11px;
  line-height: 18px;
  text-transform: none;
  letter-spacing: 0.025em;
}

.btn-uline {
  text-transform: capitalize;
  padding: 0;
}

.btn-uline:after {
  position: absolute;
  content: "";
  height: 1px;
  width: 100%;
  left: 0;
  bottom: 0;
  transition: all 0.4s;
}

.btn-uline-light {
  color: #91a5cb;
}

.btn-uline-light:after {
  background: #91a5cb;
}

.btn-uline-light:hover,
.btn-uline-light:focus,
.btn-uline-light:active {
  color: #6783b8;
}

.btn-uline-light:hover:after,
.btn-uline-light:focus:after,
.btn-uline-light:active:after {
  background: #6783b8;
}

.btn-uline-warning {
  color: #ffdc70;
}

.btn-uline-warning:after {
  background: #ffdc70;
}

.btn-uline-warning:hover,
.btn-uline-warning:focus,
.btn-uline-warning:active {
  color: #ffc100;
}

.btn-uline-warning:hover:after,
.btn-uline-warning:focus:after,
.btn-uline-warning:active:after {
  background: #ffc100;
}

.btn-uline-primary {
  color: #96abfa;
}

.btn-uline-primary:after {
  background: #96abfa;
}

.btn-uline-primary:hover,
.btn-uline-primary:focus,
.btn-uline-primary:active {
  color: #f64b1c;
}

.btn-uline-primary:hover:after,
.btn-uline-primary:focus:after,
.btn-uline-primary:active:after {
  background: #f64b1c;
}

.btn-uline-info {
  color: #f7fbff;
}

.btn-uline-info:after {
  background: #f7fbff;
}

.btn-uline-info:hover,
.btn-uline-info:focus,
.btn-uline-info:active {
  color: #87bfff;
}

.btn-uline-info:hover:after,
.btn-uline-info:focus:after,
.btn-uline-info:active:after {
  background: #87bfff;
}

.btn-uline-success {
  color: #43ffba;
}

.btn-uline-success:after {
  background: #43ffba;
}

.btn-uline-success:hover,
.btn-uline-success:focus,
.btn-uline-success:active {
  color: #00d285;
}

.btn-uline-success:hover:after,
.btn-uline-success:focus:after,
.btn-uline-success:active:after {
  background: #00d285;
}

.btn-uline-danger {
  color: #ff7070;
}

.btn-uline-danger:after {
  background: #ff7070;
}

.btn-uline-danger:hover,
.btn-uline-danger:focus,
.btn-uline-danger:active {
  color: #ff0000;
}

.btn-uline-danger:hover:after,
.btn-uline-danger:focus:after,
.btn-uline-danger:active:after {
  background: #ff0000;
}

.btn-facebook {
  background: #3b5998;
  border-color: #3b5998;
  box-shadow: 0px 2px 18px 2px rgba(59, 89, 152, 0.25);
}

.btn-facebook:hover,
.btn-facebook:focus,
.btn-facebook:active {
  color: #fff;
  background: #344e86;
  border-color: #344e86;
}

.btn-google {
  background: #d85040;
  border-color: #d85040;
  box-shadow: 0px 2px 18px 2px rgba(216, 80, 64, 0.25);
}

.btn-google:hover,
.btn-google:focus,
.btn-google:active {
  color: #fff;
  background: #d33d2b;
  border-color: #d33d2b;
}

.btn-light {
  background: #ffe4e6;
  border-color: #ffe4e6;
  box-shadow: 0px 2px 18px 2px rgba(103, 131, 184, 0.25);
}

.btn-light:hover,
.btn-light:focus,
.btn-light:active {
  color: #ff6840;
  background: #fff;
  border-color: #ff0000;
}

.btn-warning {
  background: #2f4858;
  border-color: #2f4858;
  box-shadow: 0px 2px 18px 2px rgba(255, 193, 0, 0.25);
}

.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active {
  color: #fff;
  background: #f64b1c;
  border-color: #f64b1c;
}

.btn-primary {
  background: #f64b1c;
  border-color: #f64b1c;
  box-shadow: 0px 2px 18px 2px rgba(43, 86, 245, 0.25);
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
  color: #fff;
  background: #2f4858;
  border-color: #2f4858;
}

.btn-info {
  background: #87bfff;
  border-color: #87bfff;
  box-shadow: 0px 2px 18px 2px rgba(135, 191, 255, 0.25);
}

.btn-info:hover,
.btn-info:focus,
.btn-info:active {
  color: #fff;
  background: #6eb1ff;
  border-color: #6eb1ff;
}

.btn-success {
  background: #00d285;
  border-color: #00d285;
  box-shadow: 0px 2px 18px 2px rgba(0, 210, 133, 0.25);
}

.btn-success:hover,
.btn-success:focus,
.btn-success:active {
  color: #fff;
  background: #00b975;
  border-color: #00b975;
}

.btn-danger {
  background: #ff0000;
  border-color: #ff0000;
  box-shadow: 0px 2px 18px 2px rgba(255, 0, 0, 0.25);
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active {
  color: #fff;
  background: #e60000;
  border-color: #e60000;
}

.btn-grp {
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: -10px;
  margin-bottom: -10px;
}

.btn-grp li {
  padding-top: 10px;
  padding-bottom: 10px;
}

.btn-grp .btn-uline {
  position: relative;
  top: -4px;
}

.note {
  padding: 15px 15px 15px 45px;
  border-radius: 4px;
  position: relative;
}

.note-no-icon {
  padding: 15px;
}

.note-no-icon.note-lg {
  padding: 15px 25px;
}

.note [class*="fa"] {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  font-size: 12px;
  line-height: 20px;
  width: 45px;
  text-align: center;
}

.note p {
  font-size: 12px !important;
  line-height: 1.5em;
}

.note-md p {
  font-size: 13px !important;
}

.note-plane {
  padding: 0 0 0 20px;
  background: transparent !important;
}

.note-plane [class*="fa"] {
  top: 0;
  transform: translateY(0);
  left: 0;
  font-size: 12px;
  line-height: 18px;
  width: 20px;
  text-align: left;
}

.note-info {
  background: #edf5ff;
}

.note-info p {
  color: #6783b8 !important;
}

.note-info [class*="fa"] {
  color: #87bfff;
}

.note-success {
  background: rgba(0, 210, 133, 0.15);
}

.note-success p {
  color: rgba(0, 210, 133, 0.9) !important;
}

.note-success [class*="fa"] {
  color: #00d285;
}

.note-danger {
  background: rgba(255, 0, 0, 0.05);
}

.note-danger p {
  color: rgba(255, 0, 0, 0.7) !important;
}

.note-danger [class*="fa"] {
  color: rgba(255, 0, 0, 0.7);
}

.alert-xs {
  padding: 5px 12px;
  font-size: 11px;
  line-height: 18px;
  text-transform: none;
  letter-spacing: 0.025em;
}

.alert-success {
  color: #fff;
  background: #00d285;
  border-color: #00d285;
  box-shadow: 0px 2px 18px 2px rgba(0, 210, 133, 0.25);
}

.alert-error {
  color: #fff;
  background: #ff0000;
  border-color: #ff0000;
  box-shadow: 0px 2px 18px 2px rgba(255, 0, 0, 0.25);
}

.alert-box {
  text-align: center;
  border-radius: 4px;
  padding: 7px;
  display: block;
  border: 1px solid;
  margin-bottom: 30px;
}

.alert-primary {
  background: #def8ff;
  border-color: #bae2ed;
}

.alert-txt {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
  font-size: 12px;
}

.alert-txt .ti,
.alert-txt .iconfont,
.alert-txt [class*="fa-"] {
  margin-right: 10px;
  line-height: 36px;
  width: 36px;
  border-radius: 50%;
  text-align: center;
  background: #f2b2a7;
  color: #af4038;
  flex-shrink: 0;
}
.color-light {
  color: #6783b8;
}

.color-dark {
  color: #304073;
}

.color-warning {
  color: #ffc100;
}

.color-primary {
  color: #f64b1c;
}

.color-info {
  color: #87bfff;
}

.color-success {
  color: #00d285;
}

.color-danger {
  color: #ff0000;
}

/** Table **/
.table tr {
  /* background: rgb(47 72 88); */
  background-color: #fff !important;
  color: #000;
}

.table tr:nth-child(even) {
  /* background: rgb(47 72 88); */
  background-color: #21252af2;
}

.table tr:last-child td:first-child {
  border-radius: 0 0 0 4px;
}

.table tr:last-child td:last-child {
  border-radius: 0 0 4px 0;
}

.table td {
  padding: 10px 20px;
  border: none;
  white-space: nowrap;
  color: #fff;
  vertical-align: middle;
}

.table td span {
  color: #000;
  display: block;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 1.1;
  padding-top: 5px;
}

.table thead tr {
  background: #d4e0f3;
}

.table thead th {
  padding: 20px 20px;
  border: none;
  color: #6783b8;
  white-space: nowrap;
}

.table thead th:first-child {
  border-radius: 4px 0 0 0;
}

.table thead th:last-child {
  border-radius: 0 4px 0 0;
}
body
/** Accordion @simple **/
.accordion-simple {
  padding-bottom: 25px;
}

.accordion-simple:last-child {
  padding-bottom: 0;
}

.accordion-item {
  padding-bottom: 0px;
  border: none;
}

.accordion-item:last-child {
  padding-bottom: 0;
}
.accordion-item {
  color: #f75428;
  border: 1px solid #f75d33 !important;
  border-radius: 5px;
  margin-bottom: 8px;
  /* border-bottom:  1px solid #f75d33 !important; */
}
.content-z-index {
  position: relative;
  z-index: 99;
}
.accordion-content {
  position: relative;
  overflow: hidden;
}
.accordion-content p {
  font-size: 13px !important;
  line-height: 23px !important;
}
/* .accordion-content:before, .accordion-content:after {
  position: absolute;
  content: "";
  border-radius: 50%;
  background:#ffedede0;
}
.accordion-content:before {
  height: 130px;
  width: 130px;
  bottom: -50px;
  right: -50px;
}
.accordion-content:after {
  height: 90px;
  width: 90px;
  bottom: 15px;
  right: 0;
  opacity:0.5;
} */
.accordion-item:not(:last-child) {
  border-bottom: 1px solid #f75d33 !important;
}
.accordion-heading {
  cursor: pointer;
  color: #f75428;
  border-radius: 5px;
  padding: 10px;
  font-weight: 500;
  transition: all 0.4s;
  margin-bottom: 0px;
  padding-left: 20px;
  font-size: 1em;
  position: relative;
}
/* .accordion-heading:before,
.accordion-heading:after {
  position: absolute;
  content: "";
} */

/* .accordion-heading:before {
  left: 0;
  top: 10px;
  height: 1px;
  width: 11px;
  background: #6783b8;
} */

.accordion-heading:after {
  left: 5px;
  top: 5px;
  height: 11px;
  width: 1px;
  background: #6783b8;
  transform: scaleX(1) scaleY(0);
  transition: all 0.3s ease;
}
.info-section {
  border: 2px solid #dee2e6;
  border-radius: 8px;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  margin-bottom: 35px;
}
.info-section .welcome-txt{
  text-align: center;
}
.user-panel .user-panel-content .about-wallet{
  cursor: pointer;
}
.accordion-heading.collapsed:after {
  transform: scaleX(1) scaleY(1);
}

/* .accordion-heading:hover {
  color: #304073;
} */
.public-sale-text {
  text-transform: capitalize !important;
}

.accordion-content {
  padding: 0 15px 15px 20px;
}

.or-saparator {
  position: relative;
  padding: 10px 0;
}

.or-saparator:before {
  position: absolute;
  top: 50%;
  left: 0;
  height: 1px;
  margin-top: 1px;
  width: 100%;
  content: "";
  background: rgba(40, 56, 76, 0.1);
  z-index: 1;
}

.or-saparator span {
  position: relative;
  display: inline-block;
  text-transform: uppercase;
  font-size: 0.81em;
  padding: 0 8px;
  background: #fff;
  color: #6783b8;
  z-index: 2;
}

.small-heading {
  display: block;
  text-transform: uppercase;
  font-size: 0.8em;
  line-height: 1;
  letter-spacing: 0.2em;
  color: #304073;
  margin-bottom: 20px;
}

.simple-link {
  font-size: 13px;
}

.simple-link .ti {
  font-size: 10px;
  font-weight: bold;
}

/** Dropdown **/
.dropdown-menu {
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.08);
  border: none;
}

/** Tooltip **/
[data-toggle="tooltip"] {
  cursor: help;
}

.tooltip-inner {
  background: #fff;
  font-size: 10px;
  color: #6783b8;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.07);
}

.tooltip.bs-tooltip-right .arrow:before {
  border-right-color: #fff !important;
}

.tooltip.bs-tooltip-left .arrow:before {
  border-left-color: #fff !important;
}

.tooltip.bs-tooltip-bottom .arrow:before {
  border-bottom-color: #fff !important;
}

.tooltip.bs-tooltip-top .arrow:before {
  border-top-color: #fff !important;
}

.modal-content {
  position: relative;
  border-radius: 8px;
  border: 2px solid #d3e0f3;
  box-shadow: 0px 0px 50px 0px rgba(48, 64, 115, 0.1);
  max-width: 620px;
  width: 100%;
  padding: 10px;
}

.modal-content .modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  display: inline-block;
}

.modal-content .modal-close .fa,
.modal-content .modal-close .ti {
  font-size: 20px;
  height: 44px;
  width: 44px;
  line-height: 44px;
  text-align: center;
  background: #c1d1e8;
  border-radius: 50%;
  color: #304073;
  text-shadow: none;
  display: block;
  transition: all 0.4s;
}
/** Select **/
select:focus {
  outline: none;
  border: none;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 40px;
  font-size: 0.9em;
  padding-left: 15px;
  padding-right: 44px;
  color: #304073;
}

.select2-container[dir="rtl"]
  .select2-selection--single
  .select2-selection__rendered {
  padding-right: 20px;
  padding-left: 44px;
}

.select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background: #e3f0ff;
  color: #304073;
}

.select2-container--default .select2-results__option[aria-selected="true"] {
  background: #e3f0ff;
  color: #304073;
}

.select2-container--default .select2-results__option {
  padding: 12px 20px;
  border-bottom: 1px solid #edf5ff;
}

.select2-container--default .select2-results__option:last-child {
  border-bottom: none;
}

.select2-container .select2-selection--single {
  height: 44px;
}

.select2-container--default .select2-selection--single {
  background: #fff;
  color: #8599c6;
  border: 2px solid #d3e0f3;
  border-radius: 4px;
  transition: all 0.4s;
}

.select2-container--default .select2-selection--single:focus {
  outline: none;
}

.select2-container--open.select2-container--default .select2-selection--single {
  border: 2px solid #a1cdff;
}

/* badge-presale */
.badge-presale {
  display: flex;
  background: orangered;
  color: white;
  height: 32px;
  padding: 5px 10px 5px 10px;
  margin-left: 6px;
  border-radius: 20px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 3px;
}

/*  */
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 44px;
  width: 44px;
  top: 0;
  right: 0;
  transition: all 0.5s ease;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__arrow:after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 0;
  width: 0;
  content: "";
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #8599c7;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  display: none;
}

.select2-container--default.select2-container--open
  .select2-selection--single
  .select2-selection__arrow {
  transform: rotate(-180deg);
}

.select2-container.select2-container--open {
  width: auto !important;
  max-width: 100% !important;
}

.select2-container.select2 {
  width: 100% !important;
}

.auto-width .select2-container.select2 {
  width: auto !important;
}

.select2-container--default .select2-results > .select2-results__options {
  max-height: 400px;
  overflow: scroll;
  background: #fff;
  border: 2px solid #a1cdff;
}

.select2-container--default
  .select2-dropdown--below
  .select2-results
  > .select2-results__options {
  border-radius: 0 0 4px 4px;
  border-top: none;
}

.select2-container--default
  .select2-dropdown--above
  .select2-results
  > .select2-results__options {
  border-radius: 4px 4px 0 0;
  border-bottom: none;
}

.select2-search--dropdown {
  display: none;
}

.select2-dropdown {
  border-radius: 0;
  border: none;
  background: transparent;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.03);
}

.select2-results__option {
  padding: 6px 20px;
  transition: all 0.5s ease;
}

/** Topbar **/
.topbar {
  background: #fff;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  min-width: 310px;
  box-shadow: 0px 5px 7px 0px rgba(0, 0, 0, 0.02);
}

.topbar-md {
  position: relative;
  background: #fff;
  z-index: 2;
  text-align: center;
}

.topbar-action {
  border-top: 1px solid rgba(234, 243, 252, 0.5);
  box-shadow: 0px 5px 7px 0px rgba(0, 0, 0, 0.02);
  transition: all 0.4s ease;
  padding: 0px 10px;
  border-radius: 10px;
}

.topbar-action.active {
  opacity: 1;
  top: 64px;
  visibility: visible;
}

.topbar-action-list {
  display: inline-flex;
  align-items: center;
  margin: 0 -15px;
  justify-content: center;
}


.topbar-action-list .topbar-action-item {
  padding: 0 15px;
}

.topbar-action-item {
  flex-shrink: 0;
}

.topbar-action-item > a {
  padding: 15px 0;
  display: inline-block;
  color: #5e698c;
}

.topbar-action-item > a:hover {
  color: #f64b1c;
}

.topbar-action-item .dropdown-menu {
  min-width: auto;
  margin: 0;
  padding: 0;
}

.topbar-action-item .user-box {
  padding: 10px 20px;
}

.topbar-action-link a {
  font-size: 14px;
  line-height: 30px;
}

.topbar-action-link .ti,
.topbar-action-link .icofont,
.topbar-action-link [class*="fa-"] {
  font-size: 18px;
  margin-right: 10px;
  line-height: 30px;
}

.topbar-action-language a {
  color: #323d63;
  font-weight: 500;
}

.topbar-action-language a:hover {
  color: #f64b1c;
}

.topbar-action-language.show > a {
  color: #f64b1c;
}

.topbar-action-language .ti,
.topbar-action-language .icofont,
.topbar-action-language [class*="fa-"] {
  font-size: 12px;
  margin-left: 5px;
  line-height: 30px;
  font-weight: 400;
}

.topbar-action-language .dropdown-menu li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.02);
}

.topbar-action-language .dropdown-menu li:last-child {
  border-bottom: none;
}

.topbar-action-language .dropdown-menu li a {
  display: block;
  line-height: 18px;
  padding: 7px 25px;
  font-size: 14px;
  letter-spacing: 0.1em;
}

.topbar-action-language.dropdown .dropdown-menu {
  top: 100% !important;
  left: 50% !important;
  transform: translate(-50%, 0) !important;
  margin-top: 11px;
}

.topbar-action-language.dropdown .dropdown-menu:before {
  position: absolute;
  top: -7px;
  left: 50%;
  margin-left: -7px;
  height: 0;
  width: 0;
  content: "";
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #fff;
}

.topbar-action-language.dropup .dropdown-menu {
  margin-bottom: -10px;
}

.topbar-action-user .dropdown-menu {
  margin-top: 11px;
  margin-right: -5px;
}

.topbar-action-user > a img {
  height: 30px;
  width: 30px;
  border-radius: 50%;
}

.topbar-action-user .user-box {
  text-align: right;
}

.toggle-nav,
.toggle-action {
  width: 30px;
  display: block;
  transition: all 0.4s;
  height: 30px;
  padding-top: 9px;
  cursor: pointer;
}

.toggle-nav {
  left: 10px;
}

.toggle-nav .toggle-icon {
  margin: -7.5px 0;
}

.toggle-nav.active .toggle-line {
  width: 30px;
}

.toggle-nav .toggle-icon .toggle-line:nth-last-of-type(1) {
  transform-origin: bottom left;
  /* transform: rotate(-45deg); */
  /* transform: rotate(45deg);
    top: 6px;
    transform-origin: top center; */
}

/* .toggle-nav .toggle-icon .toggle-line:nth-last-of-type(2) {
  opacity: 0;
}

.toggle-nav .toggle-icon .toggle-line:nth-last-of-type(3) {
  opacity: 0;
} */

.toggle-nav .toggle-icon .toggle-line:nth-last-of-type(4) {
  transform-origin: top left;
  /* transform: rotate(45deg) !important; */
  /* transform: rotate(-45deg);
    bottom: 15px;
    transform-origin: bottom center; */
}
/* active */
.toggle-nav .toggle-icon.active .toggle-line:nth-last-of-type(1) {
  transform: rotate(45deg);
  top: -10px;
  transform-origin: top center;
}

.toggle-nav .toggle-icon.active .toggle-line:nth-last-of-type(2) {
  opacity: 0;
}

.toggle-nav .toggle-icon.active .toggle-line:nth-last-of-type(3) {
  opacity: 0;
}

.toggle-nav .toggle-icon.active .toggle-line:nth-last-of-type(4) {
  transform: rotate(-45deg);
  bottom: -10px;
  transform-origin: bottom center;
}
/* togggle2 */

/*  */
.toggle-action {
  right: 10px;
}

.toggle-action .toggle-icon {
  margin: -4px 0;
}

.toggle-action.active .toggle-dot {
  position: relative;
}

.toggle-action.active .toggle-dot:nth-last-of-type(1) {
  transform: translate(-9px, -8px);
}

.toggle-action.active .toggle-dot:nth-last-of-type(3) {
  transform: translate(9px, 8px);
}

.site-toggle.toggle-nav .toggle-line {
  display: block;
  width: 28px;
  height: 2px;
  line-height: 0;
  background: #6783b8;
  margin: 5px 0;
  transition: all 0.4s;
}

.site-toggle.toggle-nav .toggle-line:nth-last-of-type(2) {
  width: 70%;
}

.site-toggle.toggle-nav .toggle-line:nth-last-of-type(3) {
  width: 85%;
}

.toggle-dot {
  display: block;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  border: 1px solid #6783b8;
  margin: 2px auto;
  transition: all 0.4s;
}

.site-brand {
  display: inline-block;
}

/** User **/
.user-sidebar {
  position: fixed;
  width: 260px;
  z-index: 99;
  top: 0;
  left: -260px;
  height: 100%;
  overflow: scroll;
  padding: 15px 20px 20px 20px;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.05);
  transition: all 0.4s ease;
  display: none;
}

.user-sidebar:before {
  position: fixed;
  top: 0;
  left: -260px;
  content: "";
  height: 100%;
  width: 260px;
  background: #fff;
  z-index: 1;
  transition: all 0.4s ease;
}

.user-sidebar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  content: "";
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
  transition: all 0.4s;
  visibility: hidden;
  opacity: 0;
  z-index: -1;
}

.user-sidebar.active {
  left: 0;
}

.user-sidebar.active:before {
  left: 0;
}

.user-sidebar.active .user-sidebar-overlay {
  opacity: 1;
  visibility: visible;
  transition-delay: 0.6s;
}
.user-sidebar {
  display: inherit;
}
.user-sidebar-mobile {
  width: 350px;
  display: block;
  position: fixed;
  background: white;
  padding-top: 15px;
  left: 0;
  top: 0;
  left: -100%;
  transition: 850ms;
  max-height: 100vh;
  overflow: hidden;
  overflow-y: auto;
  height: 100%;
  z-index: 10;
}
.user-sidebar-mobile.active {
  left: 0;
  transition: 350ms;
}
.right-toggle {
  display: flex;
  justify-content: flex-end;
  /* padding: 0px 20px 0px 0px !important; */
}
.toggle-cross .toggle-line:nth-last-of-type(1) {
  transform: rotate(45deg);
  top: 15px;
  transform-origin: top center;
  color: #415076 !important;
}
.navbar-toggle .toggle-line:nth-last-of-type(2) {
  transform: rotate(-45deg);
  bottom: 15px;
  transform-origin: bottom center;
  color: #415076 !important;
}
.user-sidebar > *:not(.user-sidebar-overlay) {
  position: relative;
  z-index: 2;
}

.user-sidebar-sap {
  width: 30px;
  height: 3px;
  background: #d3e0f3;
}

.user-sidebar .topbar-action-link a {
  font-size: 12px;
}

.user-sidebar-shown {
  overflow: hidden;
}

.user-content {
  flex-grow: 1;
  max-width: 100%;
}

.user-panel {
  padding: 20px;
  background: #fff;
  border-radius: 4px;
  min-height: 100%;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.05);
}
.user-panel .theme-shadow .user-panel-video{width: 100% !important; height:385px !important;}
.borderBtm{
  border-bottom: 2px solid #e6ebf4;
}
.user-panel .tokenSale-Table .table td span, .user-panel .tokenSale-Table .table th span {
  font-size: 14px;
  font-weight: 600;
}
.user-panel .tokenSale-Table .table td span.lightText, .user-panel .tokenSale-Table .table th span.lightText{
  display: block;
  font-weight: 400;
  text-transform: inherit;
}
.user-panel .tokenSale-Table .table thead th {
  vertical-align: middle;
}
.user-panel .tokenSale-Table td , .user-panel .tokenSale-Table tr, .user-panel .tokenSale-Table th {
  width: auto;
}
.user-panel tbody, .user-panel td, .user-panel tfoot, .user-panel th, .user-panel thead, .user-panel tr{
  width: 100%;
}
.bonusWeeks{
  display: flex;
}
.bonusWeeks li:not(:last-child){
  margin-right: 40px;
}
.bonusWeeks span.theme-color{
  color: #F64B1C;
}
.user-panel .user-blnc {
  margin-bottom: 30px;
}
.user-panel-title {
  color: #304073;
}

.user-panel-subtitle {
  color: #6783b8;
  font-weight: 400;
  margin-bottom: 8px;
}

.user-panel p {
  font-size: 14px;
  color: #000;
}

.user-panel-content img {
  margin-bottom: 30px;
}

.user-panel-content .embed-responsive {
  margin-bottom: 30px;
}

.user-kyc {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.05);
}

.user-kyc p {
  font-size: 14px;
  color: #304073;
}

.user-dashboard {
  padding-top: 80px;
}

.user-box {
  text-align: center;
}

.user-dropdown {
  min-width: 260px;
  box-shadow: 0px 6px 22px 0px rgba(0, 0, 0, 0.05);
}

.user-dropdown-head {
  background: #f64b1c;
  padding: 20px 20px 12px 20px;
  border-radius: 4px 4px 0 0;
  position: relative;
}

.user-dropdown-head:after {
  position: absolute;
  right: 12px;
  top: -7px;
  height: 0;
  width: 0;
  content: "";
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #f64b1c;
}

.user-dropdown-name {
  color: #fff;
  margin-bottom: 0;
  line-height: 1;
}

.user-dropdown-name span {
  font-size: 11px;
  text-transform: uppercase;
  color: #fff;
  line-height: 1;
}

.user-dropdown-email {
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
}

.user-dropdown-balance {
  padding: 14px 20px 17px 20px;
}

.user-dropdown-balance h6 {
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 0;
  letter-spacing: 0.1em;
  color: #6783b8;
}

.user-dropdown-balance h3 {
  font-weight: 400;
  margin-bottom: 0;
  letter-spacing: -0.025em;
  color: #304073;
  line-height: 1;
  margin-top: 3px;
}

.user-dropdown-balance ul {
  margin: 0 -8px;
}

.user-dropdown-balance ul li {
  color: #9eaecf;
  display: inline-block;
  padding: 0 8px;
  font-size: 11px;
  line-height: 18px;
}

.user-dropdown-btns {
  padding: 5px 0;
  border-top: 2px solid rgba(239, 246, 255, 0.5);
  width: 100%;
  justify-content: center;
}

.user-dropdown-links {
  border-top: 2px solid rgba(239, 246, 255, 0.5);
  margin-top: 0;
  padding: 5px 0;
}

.user-dropdown-links li a {
  color: #6783b8;
  font-size: 15px;
  padding: 4px 20px;
  display: block;
}

.user-dropdown-links li a:hover {
  color: #f64b1c;
}

.user-dropdown-links li a .ti {
  width: 30px;
  display: inline-block;
}

.user-dropdown-links li.active a {
  color: #f64b1c;
}

.user-image img {
  width: 70px;
  height: 70px;
}

.user-name {
  color: #304073;
  margin-top: 8px;
  margin-bottom: 8px;
  white-space: nowrap;
}

.user-uid {
  padding-bottom: 10px;
  color: #6783b8;
  font-size: 12px;
  line-height: 1;
  font-weight: 700;
  padding-bottom: 10px;
  text-transform: uppercase;
}

.user-uid span {
  padding-top: 4px;
  display: block;
  color: #304073;
  font-size: 13px;
  font-weight: 400;
}

.user-icon-nav {
  padding: 35px 0;
}

.user-icon-nav li {
  padding: 5px 0;
}

.user-icon-nav li a {
  color: #6783b8;
  font-size: 15px;
  line-height: 24px;
  font-weight: 400;
  display: flex;
  align-items: center;
}

.user-icon-nav li a.active,
.user-icon-nav li a:hover {
  color: #f64b1c;
  font-weight: 500;
}

.user-icon-nav li a .ti,
.user-icon-nav li a .icofont,
.user-icon-nav li a [class*="fa-"] {
  width: 35px;
  font-size: 20px;
  line-height: 24px;
  color: #f64b1c;
  text-shadow: 2.121px 2.121px 0.5px rgba(51, 204, 255, 0.2);
}

.user-icon-nav li.active a {
  color: #f64b1c;
  font-weight: 500;
}

.user-icon-nav li:hover a,
.user-icon-nav li:focus a {
  color: #f64b1c;
}

.user-nav {
  padding: 30px 0;
}

.user-nav li {
  padding: 5px 0;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 20px;
  color: #6783b8;
}

.user-nav li a {
  font-weight: 700;
  text-transform: none;
  font-size: 12px;
  line-height: 20px;
  color: #6783b8;
  display: block;
}

.user-nav li a:hover {
  color: #f64b1c;
}

.user-nav li span {
  font-weight: 400;
  text-transform: none;
  display: block;
  color: #304073;
  font-size: 13px;
}

.user-nav li.active a {
  color: #f64b1c;
}

.user-ath {
  background: url(../images/bg-ath.png) no-repeat 50% 50%, #fff5f6;
  background-size: cover;
}

.user-ath-page {
  display: flex;
  min-height: 100vh;
  align-items: center;
  padding: 80px 0;
}

.user-ath-logo {
  margin-bottom: 25px;
}

.user-ath-box {
  position: relative;
  padding: 45px 40px 40px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.05);
}

.user-ath-box:after {
  position: absolute;
  left: 0;
  top: 0;
  height: 5px;
  width: 100%;
  content: "";
  background: repeating-linear-gradient(
    90deg,
    #ff6c3f 0%,
    #00bffa 33.34%,
    #6783b8 33.35%,
    #fe4350 66.68%,
    #fe4350 66.69%,
    #8255fd 100%
  );
  background-size: 50.5%;
  overflow: hidden;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.user-ath-form .note {
  margin-bottom: 15px;
}
/** Notification List **/
.notification-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
  line-height: 1.4em;
}

.notification-list li:last-child {
  padding-bottom: 0;
}

/** Cards **/
.tile-item {
  border-radius: 4px;
  padding: 34px 20px 30px;
  color: #fff;
  overflow: hidden;
  position: relative;
  height: 100%;
  box-sizing: border-box;
}
.tile-item .your-contribution .text-dark:first-child{padding-left: 0;}
.tile-item .your-contribution .text-dark:last-child{padding-right: 0;}
.tile-bubbles {
  position: absolute;
  left: -25px;
  top: -25px;
  height: 120px;
  width: 120px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
}

.tile-bubbles:before,
.tile-bubbles:after {
  position: absolute;
  content: "";
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.05);
}

.tile-bubbles:before {
  height: 177px;
  width: 177px;
  top: 90px;
  left: -7px;
}

.tile-bubbles:after {
  height: 144px;
  width: 144px;
  top: 60px;
  left: 60px;
}

.tile-primary {
  background: #f64b1c;
}

.tile-light {
  background: #eff6ff;
  border: 2px solid #dce6f5;
  padding: 32px 17px 28px;
}

.tile-title {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 10px;
  letter-spacing: 0.1em;
}

.tile-light .tile-title {
  color: #304073;
}

.tile-info {
  font-weight: 400;
  font-size: 19px;
  margin-bottom: 0;
  letter-spacing: -0.025em;
  color: #fff;
}

.tile-info-list {
  margin: 0 -10px;
  padding-top: 8px;
  color: #6783b8;
  font-size: 12px;
}

.tile-info-list li {
  display: inline-block;
  padding: 0 10px;
  font-weight: 400;
}

.tile-info-list span {
  color: #f64b1c;
  display: block;
  font-size: 19px;
  line-height: 1;
  padding-bottom: 12px;
}

.tile-list-inline {
  margin: 0 -8px;
}

.tile-list-inline li {
  color: #89c3ff;
  display: inline-block;
  padding: 0 8px;
  font-size: 11px;
  line-height: 18px;
}

.info-card {
  margin-bottom: 30px;
  border-radius: 4px;
}

.info-card h4 {
  line-height: 28px;
}

.info-card p {
  font-size: 14px;
  color: #304073;
  margin-bottom: 10px;
}

.info-card p:last-child {
  margin-bottom: 0;
}

.info-card-bordered {
  padding: 15px;
  border: 2px solid #dce6f5;
}

.info-card-image img {
  max-width: 100%;
  width: 200px;
}
.token-card {
  border-radius: 6px;
  background: #ff843e;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  margin-bottom: 30px;
}

.token-info {
  width: 100%;
  flex-grow: 1;
  padding: 20px 10px 20px 50px;
  position: relative;
}

.token-smartag {
  position: absolute;
  color: #f64c1d;
  text-transform: uppercase;
  background: #fff;
  line-height: 20px;
  font-size: 12px;
  font-weight: 700;
  padding: 7px 30px;
  min-width: 200px;
  text-align: center;
  top: 30px;
  left: -55px;
  transform: rotate(-45deg);
}

.token-bonus {
  color: #fff;
  margin-bottom: 14px;
  padding-left: 25px;
}

.token-bonus span {
  font-size: 0.5em;
  color: #fff;
}

.token-timeline {
  margin: 0 -16px;
}

.token-timeline li {
  padding: 0 16px;
  font-weight: 500;
  display: inline-block;
  color: #fff;
}

.token-timeline li span {
  display: block;
  font-weight: 600;
  color: #304858;
  line-height: 1;
  font-size: 12px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.token-countdown {
  flex-grow: 1;
  background: #f64b1c;
  padding: 25px;
  text-align: center;
}

.token-countdown-title {
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 19px;
  line-height: 22px;
  letter-spacing: 0.1em;
  margin-bottom: 6px;
  display: block;
}
.token-countdown-title .text-primary {
  color: #2f4858 !important;
}
.token-countdown-clock {
  display: flex;
  color: #fff;
}

.token-countdown-clock .countdown-time {
  font-weight: 500;
  font-size: 24px;
  line-height: 1;
}

.token-countdown-clock .countdown-text {
  font-size: 12px;
  line-height: 1;
  display: block;
}
.progress-card {
  border-radius: 4px;
  padding: 20px 15px;
  border: 2px solid #dce6f5;
}

.progress-card h4 {
  margin-bottom: 16px;
}

.progress-info {
  display: flex;
  justify-content: space-between;
}

.progress-info li {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
}

.progress-info li span {
  color: #304073;
  font-weight: 400;
}

.progress-bar {
  display: block;
  color: #f64b1c;
  background: #f64b1c;
  overflow: visible;
  height: 6px;
  border-radius: 3px;
  margin: 15px 0 57px;
  position: relative;
}

.progress-hcap,
.progress-scap,
.progress-psale,
.progress-percent {
  position: absolute;
  top: 0;
  left: 0;
  height: 6px;
  border-radius: 3px;
}

.progress-hcap > div,
.progress-scap > div,
.progress-psale > div,
.progress-percent > div {
  position: absolute;
  right: 0;
  transform: translateX(50%);
  width: 120px;
  font-size: 10px;
  line-height: 14px;
  font-weight: 500;
  padding: 30px 0 0 0;
  text-transform: uppercase;
}

.progress-hcap > div:after,
.progress-scap > div:after,
.progress-psale > div:after,
.progress-percent > div:after {
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -2px;
  width: 2px;
  height: 28px;
  content: "";
  background: #fbbe9c;
}

.progress-hcap > div span,
.progress-scap > div span,
.progress-psale > div span,
.progress-percent > div span {
  display: block;
  color: #ff843ed1;
}

.progress-hcap {
  background: #fbbe9c;
}

.progress-scap {
  background: #f64b1c;
}

.progress-psale {
  background: #f64b1c;
}

.progress-percent {
  background: #f64b1c;
}

.progress-percent:after {
  position: absolute;
  content: "";
  right: 0;
  top: 50%;
  height: 18px;
  width: 18px;
  border: 3px solid #f64b1c;
  background: #fff;
  border-radius: 50%;
  transform: translate(50%, -50%);
}
.status {
  padding: 40px 20px;
  border-radius: 4px;
  text-align: center;
  border: 1px solid #dce6f5;
}

.status-sm {
  padding: 20px;
}

.status:not(:last-child) {
  margin-bottom: 30px;
}

.status:not(:first-child) {
  margin-top: 10px;
}

.status-icon {
  position: relative;
  height: 80px;
  width: 80px;
  background: #fff;
  border-radius: 50%;
  text-align: center;
  margin: 0 auto 10px;
  border: 2px solid #c8d8f0;
}

.status-icon > .ti {
  color: #c9d9f1;
  font-size: 30px;
  line-height: 76px;
}

.status-icon-sm {
  position: absolute;
  right: -2px;
  bottom: -2px;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background: #fff;
  border: 1px solid #ffc7c7;
}

.status-icon-sm > .ti {
  font-size: 12px;
  line-height: 22px;
  color: #ffc7c7;
  display: block;
}

.status-text {
  display: block;
  font-size: 18px;
  font-weight: 400;
  color: #6783b8;
  letter-spacing: -0.01em;
  padding-bottom: 10px;
}
.user-success-msg .form-note a{padding: 6px 12px; background-color: #f75d33; color: #fff;border: 1px solid #f75d33; transition: all 0.4s; border-radius: 3px;}
.status .btn {
  margin-top: 10px;
}

.status-empty .status-icon {
  background: #eff6ff;
  border-color: #c8d8f0;
}

.status-empty .status-icon > .ti {
  color: #c9d9f1;
}

.status-empty .status-icon-sm {
  border-color: #ffc7c7;
}

.status-empty .status-icon-sm > .ti {
  color: #ffc7c7;
}

.status-thank .status-icon,
.status-verified .status-icon {
  border-color: #06d388;
}

.status-thank .status-icon > .ti,
.status-verified .status-icon > .ti {
  color: #06d388;
}

.status-thank .status-icon-sm,
.status-verified .status-icon-sm {
  border-color: #06d388;
}

.status-thank .status-icon-sm > .ti,
.status-verified .status-icon-sm > .ti {
  color: #06d388;
}

.status-verified .status-text {
  color: #06d388;
}

.status-process .status-icon {
  border-color: #50b3ff;
}

.status-process .status-icon > .ti {
  color: #50b3ff;
}

.status-process .status-icon-sm {
  border-color: #50b3ff;
}

.status-process .status-icon-sm > .ti {
  color: #50b3ff;
}

.status-canceled .status-icon {
  border-color: #ffc7c7;
}

.status-canceled .status-icon > .ti {
  color: #ffc7c7;
}

.status-canceled .status-icon-sm {
  border-color: #ffc7c7;
}

.status-canceled .status-icon-sm > .ti {
  color: #ffc7c7;
}

.status-canceled .status-text {
  color: #ffc7c7;
}

.status-warnning .status-icon {
  border-color: #ffd147;
}

.status-warnning .status-icon > .ti {
  color: #ffd147;
}

.status-warnning .status-icon-sm {
  border-color: #ffd147;
}

.status-warnning .status-icon-sm > .ti {
  color: #ffd147;
}
/** Tabs **/
.nav-tabs-line {
  margin-bottom: 30px;
  border-bottom: 2px solid #d3e0f3;
}

.nav-tabs-line .nav-item {
  padding: 0 12px 0 0;
  margin-bottom: 0;
}

.nav-tabs-line .nav-item:last-child {
  padding: 0;
}

.nav-tabs-line .nav-link {
  border: none;
  padding: 6px 0;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: -0.03em;
  color: rgba(103, 131, 184, 0.7);
  position: relative;
}

.nav-tabs-line .nav-link:after {
  position: absolute;
  left: 0;
  bottom: -2px;
  content: "";
  width: 0;
  height: 2px;
  background: #f64b1c;
  transition: all 0.4s;
}

.nav-tabs-line .nav-link.active {
  color: #304073;
}

.nav-tabs-line .nav-link.active:after {
  width: 100%;
}

.nav-tabs-bordered {
  display: block;
  margin: 0 -7px;
  border-bottom: 0;
  display: flex;
}

.nav-tabs-bordered .nav-item {
  margin-bottom: 0;
  flex-grow: 1;
  width: 33.33%;
  padding: 0 7px;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 20px;
}

.nav-tabs-bordered .nav-link {
  text-align: center;
  display: block;
  border: 2px solid #d3e0f3;
  border-radius: 4px;
  color: #d3e0f3;
  padding: 12px 0 10px;
  font-size: 11px;
  height: 100%;
}

.nav-tabs-bordered .nav-link.active {
  border: 2px solid #f64b1c;
  color: #f64b1c;
}

.nav-tabs-icon {
  position: relative;
  width: 44px;
  margin: 0 auto 5px;
}

.nav-tabs-icon img {
  transition: all 0.4s;
  width: 100%;
}

.nav-tabs-icon img:first-of-type {
  opacity: 1;
}

.nav-tabs-icon img:last-of-type {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.active .nav-tabs-icon img:first-of-type {
  opacity: 0;
}

.active .nav-tabs-icon img:last-of-type {
  opacity: 1;
}
/** Input Style **/
.input-item {
  padding-bottom: 15px;
  text-align: left;
}

.input-item-label {
  font-size: 14px;
  font-weight: 500;
  color: #6783b8;
  line-height: 1;
}

.input-bordered {
  border-radius: 4px;
  border: 2px solid #d3e0f3;
  width: 100%;
  padding: 10px 15px;
  line-height: 20px;
  font-size: 14px;
  color: #6783b8;
  transition: all 0.4s;
}
.input-bordered::-webkit-input-placeholder {
  color: #6783b8;
}

.input-bordered::-moz-placeholder {
  color: #6783b8;
}

.input-bordered:-ms-input-placeholder {
  color: #6783b8;
}

.input-bordered:-moz-placeholder {
  color: #6783b8;
}

.input-bordered:focus {
  box-shadow: none;
  outline: none;
  border-color: #f64b1c;
}

.input-bordered-error {
  border-color: rgba(255, 0, 0, 0.6);
}
.remove-counter::-webkit-outer-spin-button,
.remove-counter::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.buy-token-content a{
  text-decoration: underline;
}

.input-switch {
  display: none;
}

.input-switch ~ label {
  cursor: pointer;
  height: 24px;
  min-width: 50px;
  border-radius: 12px;
  margin-bottom: 0;
  display: block;
  position: relative;
}

.input-switch ~ label:before,
.input-switch ~ label:after {
  position: absolute;
  content: "";
  transition: all 0.4s;
}

.input-switch ~ label:before {
  left: 0;
  top: 0;
  height: 24px;
  width: 50px;
  border-radius: 12px;
  background: #d3e0f3;
}

.input-switch ~ label:after {
  left: 3px;
  top: 3px;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background: #fff;
}

.input-switch:checked ~ label:before {
  background: #f64b1c;
}

.input-switch:checked ~ label:after {
  left: calc(100% - 21px);
  border: 1px solid #d3e0f3;
}

.input-checkbox {
  display: none;
}

.input-checkbox + label {
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 0;
  color: #6783b8;
  padding-left: 40px;
  position: relative;
  cursor: pointer;
  transition: all 0.4s;
}

.input-checkbox + label:before,
.input-checkbox + label:after {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 2px;
  transition: all 0.4s;
}

.input-checkbox + label:before {
  content: "";
  border: 2px solid #d3e0f3;
}

.input-checkbox + label:after {
  line-height: 20px;
  text-align: center;
  font-family: themify;
  content: "\e64c";
  font-size: 10px;
  font-weight: 900;
  color: #fff;
  background: #f64b1c;
  opacity: 0;
}

.input-checkbox:checked + label:after {
  opacity: 1;
}

.input-note {
  font-size: 12px;
  color: #6783b8;
  opacity: 0.7;
}

.simple-switch {
  flex-shrink: 0;
  height: 24px;
  width: 50px;
  border-radius: 12px;
  display: inline-block;
  background: #d3e0f3;
  position: relative;
}

.simple-switch:after {
  position: absolute;
  content: "";
  transition: all 0.4s;
  left: 3px;
  top: 3px;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background: #fff;
}

.simple-switch.active {
  background: #f64b1c;
}

.simple-switch.active:after {
  left: calc(100% - 21px);
}

/** DropZone **/
.dz-clickable {
  background: #f3f8ff;
  border: 1px dashed #d3e0f3;
  border-radius: 4px;
  padding: 20px 0 30px;
  text-align: center;
}

.dz-message span {
  display: block;
  color: rgba(103, 131, 184, 0.6);
}

.dz-message-text {
  font-size: 13px;
}

.dz-message-or {
  font-size: 16px;
  margin-bottom: 4px;
  text-transform: uppercase;
}

.dz-preview {
  margin-top: 20px;
  position: relative;
}

.dz-image img {
  border-radius: 4px;
  border: 1px solid #d3e0f3;
}

.dz-filename {
  font-size: 13px;
}

.dz-progress {
  opacity: 1;
  z-index: 1000;
  pointer-events: none;
  position: absolute;
  height: 10px;
  top: 55px;
  left: 50%;
  width: 80px;
  transform: translateX(-50%);
  background: rgba(255, 255, 255, 0.9);
  border-radius: 5px;
  overflow: hidden;
  transition: all 0.4s;
}

.dz-complete .dz-progress {
  opacity: 0;
}

.dz-upload {
  background: #333;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  transition: all 0.3s ease-in-out;
}

.dz-error-message,
.dz-success-message {
  font-size: 13px;
}

.dz-error-mark,
.dz-success-mark {
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  display: none;
}

.dz-error-mark svg,
.dz-success-mark svg {
  height: 40px !important;
  width: 40px !important;
}

.dz-error-mark svg g,
.dz-success-mark svg g {
  stroke-opacity: 0.7;
  stroke-width: 2;
}

.dz-error-message {
  color: #ff0000;
}

.dz-error-mark g {
  stroke: rgba(255, 0, 0, 0.7) !important;
}

.dz-error .dz-error-mark {
  display: block;
}

.dz-success-message {
  color: #00d285;
}

.dz-success-mark g {
  stroke: rgba(0, 210, 133, 0.7) !important;
}

.dz-success .dz-success-mark {
  display: block;
}

.upload-title {
  color: #6783b8;
  display: block;
  font-size: 14px;
  padding-bottom: 8px;
  font-weight: 500;
}

/** KYC Upload **/
.kyc-upload-title {
  color: #304073;
  margin-bottom: 12px;
}

.kyc-upload-list li {
  color: #304073;
  position: relative;
  padding-left: 25px;
}

.kyc-upload-list li:before {
  position: absolute;
  left: 0;
  top: 3px;
  font-family: Font Awesome;
  content: "\f00c";
  font-weight: 700;
  color: #9eaecf;
  margin-right: 4px;
  font-size: 13px;
}

.kyc-popup {
  padding: 30px;
}
/** Form Style **/
.from-step-item {
  border-bottom: 1px solid #d3e0f3;
}

.from-step-item:last-child {
  border-bottom: none;
}

.from-step-heading {
  display: flex;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #d3e0f3;
}

.from-step-number {
  font-size: 20px;
  line-height: 24px;
  padding: 10px 0;
  flex-shrink: 0;
  font-weight: 500;
  color: #9eaecf;
  width: 48px;
  border-radius: 50%;
  text-align: center;
  border: 2px solid;
  margin-right: 15px;
}

.from-step-head h4 {
  margin-bottom: 4px;
  font-weight: 600;
}

.from-step-head p {
  color: #304073;
  font-size: 13px;
}

.from-step-content {
  padding: 20px;
  flex-grow: 1;
}
/** Payment Box **/
.payment-item {
  text-align: center;
  margin-bottom: 15px;
  position: relative;
}

.payment-item > span {
  display: block;
  font-size: 13px;
  color: #9eaecf;
  line-height: 1;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.payment-item label {
  width: 100%;
  border: 2px solid #d3e0f3;
  border-radius: 4px;
  background: #eff6ff;
  padding: 12px 20px 12px;
  cursor: pointer;
  transition: all 0.4s;
  position: relative;
  display: flex;
  align-items: center;
}

.payment-item label span {
  padding-left: 15px;
}

.payment-item label:after {
  position: absolute;
  top: -15px;
  right: -15px;
  height: 30px;
  width: 30px;
  line-height: 26px;
  content: "\f00c";
  font-weight: 600;
  font-size: 12px;
  color: #fff;
  font-family: Font Awesome;
  border-radius: 50%;
  background: #f64b1c;
  border: 3px solid #fff;
  transition: all 0.4s;
  transform: scale(0);
}

.payment-item input[type="radio"] {
  display: none;
}

.payment-item input[type="radio"]:checked ~ label {
  border-color: #f64b1c;
}

.payment-item input[type="radio"]:checked ~ label:after {
  transform: scale(1);
}

.payment-icon {
  width: 40px;
  border-radius: 50%;
  height: 40px;
}
.payment-icon img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.payment-icon [class*="fa-"] {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  line-height: 30px;
  text-align: center;
  color: #fff;
  font-size: 16px;
}

.payment-icon-eth img,
.payment-icon-eth [class*="fa-"] {
  background: #6d75b7;
}

.payment-icon-ltc img,
.payment-icon-ltc [class*="fa-"] {
  background: #bcbcbc;
}

.payment-icon-btc img,
.payment-icon-btc [class*="fa-"] {
  background: #fdb722;
}

/* .payment-icon-usd img,
.payment-icon-usd [class*="fa-"] {
  background: #229efd;
} */

.payment-cur {
  font-size: 13px;
  color: #7a95c2;
  font-weight: 400;
  font-family: "Poppins";
}

.payment-cal-cur {
  position: absolute;
  top: 0;
  right: 0;
  text-transform: uppercase;
  color: #6783b8;
  display: inline-block;
  padding: 8.5px 12px;
}

.payment-cal-cur:after {
  position: absolute;
  left: 0;
  top: 50%;
  height: 24px;
  width: 1px;
  content: "";
  transform: translateY(-50%);
  background: #dce6f5;
}
.line-none.payment-cal-cur:after {
  display: none;
}
.payment-calculator {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: -16px 0;
}

.payment-calculator > div {
  padding: 16px 0;
  width: 100%;
}

.payment-calculator .ti {
  position: absolute;
  bottom: 50%;
  color: #304073;
  left: 50%;
  transform: translate(-50%, 50%);
  margin-bottom: -4px;
}

.payment-calculator label {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 2px;
}

.payment-calculator-note {
  font-size: 12px;
}

.payment-calculator-note [class*="fa-"] {
  color: #7c98ff;
  margin-right: 5px;
  display: inline-block;
}

.payment-input {
  position: relative;
}

.payment-bonus-title {
  font-weight: 500;
  font-size: 14px;
  color: #6783b8;
  margin-bottom: 2px;
}

.payment-bonus-amount {
  display: block;
  color: #304073;
  font-size: 18px;
}

.payment-bonus-amount span {
  color: #9eaecf;
  font-size: 12px;
}

.payment-bonus-time {
  font-size: 12px;
  color: #6783b8;
  display: block;
  line-height: 1;
}

.payment-summary {
  border-radius: 4px;
  border: 1px solid #dce6f5;
  padding: 20px 18px 0;
  margin-bottom: 30px;
}

.payment-summary-item {
  padding-bottom: 10px;
  overflow: hidden;
}

.payment-summary-title {
  color: #f64b1c;
  font-size: 14px;
  margin-bottom: 0;
  font-weight: 500;
}

.payment-summary-info span:first-child:not(.payment-summary-amount) {
  color: #6783b8;
  font-size: 14px;
  overflow: hidden;
  font-weight: 400;
  position: relative;
  top: -2px;
}

.payment-summary-info span:last-child {
  font-size: 14px;
  color: #6783b8;
  overflow: hidden;
  font-weight: 500;
  text-transform: uppercase;
}

.payment-summary-amount {
  font-size: 20px;
  overflow: hidden !important;
  font-weight: 500;
  color: #304073;
}
/** Data Table **/
table.dataTable {
  width: 100%;
  overflow-x: auto;
}
.dataTables_filter label {
  margin-bottom: 0;
}

div.dataTables_wrapper div.dataTables_filter {
  text-align: center;
}

div.dataTables_wrapper div.dataTables_filter label {
  width: 100%;
  position: relative;
}

div.dataTables_wrapper div.dataTables_filter label:before {
  position: absolute;
  height: 30px;
  width: 30px;
  text-align: center;
  line-height: 40px;
  font-family: themify;
  content: "\e610";
  color: #abbbd9;
  font-size: 15px;
}

div.dataTables_wrapper div.dataTables_filter input {
  width: 100%;
  padding: 10px 30px;
  line-height: 20px;
  font-size: 13px;
  border: none;
  background: transparent;
}

div.dataTables_wrapper div.dataTables_filter input:focus {
  border: none;
  background: transparent;
  outline: none;
  box-shadow: none;
}

div.dataTables_wrapper div.dataTables_filter input:-moz-placeholder {
  opacity: 1;
  color: #abbbd9;
}

div.dataTables_wrapper div.dataTables_filter input:-ms-input-placeholder {
  opacity: 1;
  color: #abbbd9;
}

div.dataTables_wrapper div.dataTables_filter input::-webkit-input-placeholder {
  opacity: 1;
  color: #abbbd9;
}

div.dataTables_wrapper div.dataTables_length {
  text-align: center;
}

div.dataTables_wrapper div.dataTables_length label {
  color: #8599c7;
}

div.dataTables_wrapper div.dataTables_length select {
  margin-left: 10px;
}

div.dataTables_wrapper div.dataTables_info {
  color: #23406c;
  padding: 30px 0 0;
  text-align: center;
}

div.dataTables_wrapper div.dataTables_paginate {
  padding: 20px 0 0;
}

div.dataTables_wrapper div.dataTables_paginate ul.pagination {
  justify-content: center;
}

div.dataTables_wrapper > .row > .col-12 {
  padding-left: 5px;
  padding-right: 5px;
}

.clear-table {
  padding-top: 20px;
}
.form-control {
  width: 100%;
  border: none;
}

.page-link {
  padding: 10px 12px;
  margin-left: 0;
  margin: 0 4px;
  text-align: center;
  line-height: 20px;
  min-width: 40px;
  color: #abbbd9;
  background: #eff6ff;
  border: none;
  border-radius: 4px;
}

.page-link:focus {
  box-shadow: none;
}

.page-link:hover {
  color: #6783b8;
  background: #cad2f1;
}

.previous .page-link,
.next .page-link {
  background: #eff6ff;
}

.disabled.previous .page-link,
.disabled.next .page-link {
  display: none;
}

.page-item.active .page-link {
  color: #6783b8;
  background: #cad2f1;
}

.data-filter {
  display: none;
}

.data-filter + label {
  font-size: 13px;
  line-height: 18px;
  margin-bottom: 0;
  color: #6783b8;
  padding-left: 25px;
  position: relative;
  cursor: pointer;
  transition: all 0.4s;
  opacity: 0.7;
}

.data-filter + label:before,
.data-filter + label:after {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  border-radius: 2px;
}

.data-filter + label:before {
  content: "";
  border: 2px solid #6783b8;
}

.data-filter + label:after {
  line-height: 18px;
  text-align: center;
  font-family: themify;
  content: "\e64c";
  font-size: 10px;
  font-weight: 700;
  color: #fff;
  background: #f64b1c;
  opacity: 0;
}

.data-filter:checked + label {
  opacity: 1;
}

.data-filter:checked + label:after {
  opacity: 1;
}

.data-table {
  border-spacing: 0 5px !important;
}

.data-table-filter a {
  color: #6783b8;
  position: relative;
  top: 5px;
}

.data-table-filter a:hover {
  color: #f64b1c;
}

.data-table-filter .dropdown-menu {
  min-width: auto;
}

.data-table-filter .dropdown-menu li {
  padding: 5px 20px;
}

.data-table thead th {
  line-height: 10px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  color: #6783b8;
  padding: 0 0 10px;
  padding-right: 10px;
}

.data-table thead th:before,
.data-table thead th:after {
  display: none !important;
}

.data-table thead th:first-child {
  padding-left: 20px;
}

.data-table thead th:focus,
.data-table thead th:active {
  box-shadow: none;
  outline: none;
}

.data-table thead th span {
  position: relative;
  display: inline-block;
}

.data-table thead th span:before,
.data-table thead th span:after {
  position: absolute;
  top: 1px;
  opacity: 0.3;
}

.data-table thead th span:before {
  content: "\2191";
  right: -20px;
}

.data-table thead th span:after {
  content: "\2193";
  right: -13px;
}

.data-table thead th.sorting_disabled span:before,
.data-table thead th.sorting_disabled span:after {
  display: none;
}

.data-table thead th.sorting_asc span:before {
  opacity: 1;
}

.data-table thead th.sorting_desc span:after {
  opacity: 1;
}

.data-table tbody tr:hover td {
  background: #d5e7ff;
}

.data-table tbody tr.odd td {
  background: #eaf3ff;
}

.data-table tbody tr.even td {
  background: #f4f9ff;
}

.data-table tbody td {
  color: #6783b8;
  font-size: 11px;
  line-height: 1;
  font-weight: 400;
  line-height: 24px;
  padding: 10px 0;
  position: relative;
  transition: all 0.4s;
}

.data-table tbody td div > span,
.data-table tbody td > span {
  display: block;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  margin-bottom: -5px;
  color: #304073;
}

.data-table tbody td:last-child {
  border-radius: 0 3px 3px 0;
}

.data-table tbody td:first-child {
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 3px 0 0 3px;
}
/** Transaction **/
th.tranx-type span {
  padding: 0;
}

.tranx-type span {
  display: inline-block;
  border-radius: 2px;
  font-size: 12px;
  line-height: 22px;
  padding: 0 10px;
}

.tranx-type-purchase {
  background: #9fffdc;
  border: 1px solid #00d285;
}

.tranx-type-bonus {
  background: #d4e8ff;
  border: 1px solid #87bfff;
}

.tranx-type-withdraw {
  background: #fff3cc;
  border: 1px solid #ffc100;
}

.tranx-status {
  width: 20px;
}

.tranx-status .ti {
  height: 24px;
  width: 24px;
  line-height: 22px;
  border-radius: 50%;
  text-align: center;
  font-size: 12px;
  border: 1px solid;
  display: inline-block;
  background: #fff;
}

.tranx-status-approved .ti {
  color: #00d285;
}

.tranx-status-pending .ti {
  color: #ffc100;
}

.tranx-status-cancled .ti {
  color: #ff0000;
}

.tranx-amount,
/* .tranx-from {
  display: none;
} */

.tranx-action {
  width: 30px;
  padding-right: 10px !important;
}
.metamask-info {
  list-style-position: inside !important;
  list-style-type: disc !important;
  padding-left: 32px !important;
}
.metamask-info li {
  list-style: circle !important;
}
.metamask-info-num{
  padding-left: 32px !important;
}
.metamask-info-num li {
  list-style: auto;
}
.tranx-action a,
.tranx-action span {
  color: #6783b8;
  height: 30px;
  width: 30px;
  line-height: 34px;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
}

.tranx-action a:hover,
.tranx-action a:focus,
.tranx-action span:hover,
.tranx-action span:focus {
  color: #f64b1c;
  background: #d4e6f9;
  cursor: pointer;
}

.tranx-popup {
  padding: 25px;
  width: 100%;
}
.trans-confirm {
  font-size: 130px !important;
  color: #008000bf;
}

.tranx-popup h5 {
  color: #304073;
  font-weight: 500;
  margin-bottom: 13px;
}

.tranx-popup p {
  font-size: 13px;
  color: #6783b8;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tranx-popup .transction-btc-btn{
  padding: 5px 10px;
  font-size: 11px;
  font-weight: 600;
}
.tranx-popup p strong {
  font-weight: 500;
  color: #4b69a1;
}

.tranx-info-list {
  padding: 5px 0 15px;
}

.tranx-info-list li {
  display: inline-block;
  width: 48%;
  font-size: 12px;
  font-weight: 500;
  color: #304073;
}

.tranx-info-list li span {
  text-transform: uppercase;
  color: #6783b8;
  display: inline-block;
  margin-right: 5px;
}

.tranx-info-qr span {
  display: block;
  padding-bottom: 6px;
  padding-bottom: 12px;
  font-size: 13px;
  color: #6783b8;
}

.tranx-info-qrimg {
  width: 120px;
}

.tranx-payment-details.active {
  display: block !important;
}

.tranx-payment-details h6 {
  font-size: 13px;
  color: #304073;
  margin-bottom: 12px;
}

.tranx-payment-info {
  position: relative;
}

.tranx-payment-info > [class*="fa"] {
  position: absolute;
  top: 0;
  left: 22px;
  line-height: 20px;
  padding: 15px 0;
  font-size: 14px;
  text-align: center;
  color: #6783b8;
}

.tranx-payment-address {
  border: none;
  color: #6783b8;
  line-height: 20px;
  padding: 15px 40px;
  border-radius: 4px;
  border: 1px solid rgba(211, 224, 243, 0.5);
  width: 100%;
  background: #fff;
}

.tranx-payment-address:focus {
  outline: none;
  box-shadow: none;
}

.tranx-payment-copy {
  position: absolute;
  right: 6px;
  top: 6px;
  height: 40px;
  width: 40px;
  line-height: 20px;
  padding: 10px 0;
  text-align: center;
  color: #6783b8;
  background: #e9eff9;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.4s; /*-- @updated on v1.0.1 --*/
}

.tranx-payment-copy:focus,
.tranx-payment-copy:hover {
  color: #fff;
  background: #f64b1c;
  box-shadow: none;
  outline: none;
}

.tranx-copy-feedback {
  display: none;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  line-height: 20px;
  padding: 15px 0;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  text-align: center;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 4px;
  border: 1px solid rgba(211, 224, 243, 0.5);
  color: #f64b1c;
}

.tranx-purchase-details.active {
  display: block !important;
}

.tranx-purchase-details h6 {
  color: #6783b8;
  font-size: 12px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  margin-bottom: 14px;
}

.tranx-purchase-details h6 em {
  padding: 0 7px;
}

.tranx-purchase-details h6 span {
  font-weight: 400;
  text-transform: none;
  color: #6783b8;
  letter-spacing: 0.02em;
}

.tranx-purchase-info {
  border-radius: 6px;
  margin-bottom: 27px;
  border: 1px solid rgba(211, 224, 243, 0.5);
}

.tranx-purchase-info li {
  display: block;
}

.tranx-purchase-info li:last-child .tranx-purchase-des {
  border-bottom: none;
}

.tranx-purchase-head {
  font-size: 13px;
  font-weight: 600;
  color: #6783b8;
  line-height: 20px;
  padding: 14px 20px;
  width: 100%;
}

.tranx-purchase-des {
  font-size: 14px;
  color: #304073;
  line-height: 20px;
  padding: 14px 20px;
  flex-grow: 1;
  border-top: 1px solid rgba(211, 224, 243, 0.5);
  border-bottom: 1px solid rgba(211, 224, 243, 0.5);
  display: flex;
  justify-content: space-between;
}

.tranx-purchase-des span:last-child {
  font-size: 12px;
  color: #6783b8;
}
/** Activities **/
.activity-time {
  min-width: 90px;
}

.activity-device {
  display: none;
}

.activity-ip {
  padding-right: 15px !important;
}
.refferal-info {
  position: relative;
}

.refferal-info > [class*="fa"] {
  position: absolute;
  top: 0;
  left: 18px;
  line-height: 20px;
  padding: 15px 0;
  font-size: 14px;
  text-align: center;
  color: #6783b8;
}

.refferal-address {
  border: none;
  color: #6783b8;
  line-height: 20px;
  padding: 15px 40px;
  border-radius: 4px;
  border: 1px solid rgba(211, 224, 243, 0.5);
  width: 100%;
  background: #fff;
}

.refferal-address:focus {
  outline: none;
  box-shadow: none;
}

.refferal-copy {
  position: absolute;
  right: 6px;
  top: 6px;
  height: 40px;
  width: 40px;
  line-height: 20px;
  padding: 10px 0;
  text-align: center;
  color: #6783b8;
  background: #e9eff9;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  transition: all 0.4s; /*-- @updated on v1.0.1 --*/
}

.refferal-copy:focus,
.refferal-copy:hover {
  color: #fff;
  background: #f64b1c;
  box-shadow: none;
  outline: none;
}

.refferal-copy-feedback {
  display: none;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  line-height: 20px;
  padding: 15px 0;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  text-align: center;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 4px;
  border: 1px solid rgba(211, 224, 243, 0.5);
  color: #f64b1c;
}

.refferal-statistics {
  border-radius: 4px;
  border: 1px solid #dce6f5;
  padding: 20px 18px 0;
  margin-bottom: 30px;
}

.refferal-statistics-item {
  padding-bottom: 10px;
}

.refferal-statistics-item h6 {
  color: #f64b1c;
  font-size: 14px;
  margin-bottom: 0;
  font-weight: 500;
}
.refferal-bonus button{width: 50px; height: 28px; text-align: center; display: flex; justify-content: center; align-items: center; margin: 0 5px;}
.refferal-statistics-item span {
  font-size: 20px;
  font-weight: 500;
  color: #304073;
}

.refferal-tokens,
.refferal-channel {
  display: none;
}
.share-links {
  margin: 0 -10px;
}

.share-links li {
  display: inline-block;
  margin: 10px 10px;
  font-size: 14px;
  line-height: 24px;
}

.share-links li a {
  font-size: 17px;
  line-height: 24px;
  display: inline-block;
  color: #6783b8;
}

.share-links li a:hover {
  color: #f64b1c;
}

/** Author Info **/
.ath-trigger {
  display: flex;
  align-items: center;
  color: #6783b8;
  line-height: 1.4em;
}

.ath-trigger:hover,
.ath-trigger:active,
.ath-trigger:focus,
.ath-trigger.active {
  color: #6783b8;
}

.ath-trigger span {
  margin-right: 16px;
}

.ath-content {
  display: none;
}

.ath-content h5 {
  color: #6783b8;
  margin-bottom: 8px;
  margin-top: 0;
}

.ath-content-sublist {
  padding: 0 0 20px 5px;
}

.ath-content-sublist li {
  color: #6783b8;
  font-size: 12px;
  padding-bottom: 4px;
}

.ath-content-sublist li:before {
  content: "-";
  margin-right: 5px;
}

.ath-content-sublist li:last-child {
  padding-bottom: 0;
}

.ath-content-qrimg {
  width: 120px;
  margin-bottom: 5px;
}

/** Datepicker **/
.datepicker-dropdown.datepicker-orient-bottom:before {
  bottom: -8px;
  border-top: 6px solid #a6cffd;
}

.datepicker-dropdown.datepicker-orient-top:before {
  top: -8px;
  border-bottom: 6px solid #a6cffd;
}

.datepicker.dropdown-menu {
  border: none;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
  border: 2px solid #a6cffd;
}

.datepicker.dropdown-menu td {
  padding: 7px 8px;
  min-width: 35px;
}

.datepicker table thead tr th {
  transition: all 0.4s;
  color: #3c3c3c;
}

.datepicker table thead tr th:hover {
  background: #eaf3fc !important;
}

.datepicker table tr th {
  transition: all 0.4s;
}

.datepicker table tr td {
  transition: all 0.4s;
  color: #8599c6;
}

.datepicker table tr td.day:hover {
  background: #eaf3fc;
}

.datepicker table tr td.old,
.datepicker table tr td.new {
  color: rgba(133, 153, 198, 0.4);
}

.datepicker table tr td.today {
  background: #46bdf4;
  color: #fff;
}

.datepicker table tr td.today:hover:hover {
  color: #fff;
  background: #f64b1c;
}

.datepicker table tr td.active.active {
  background: #f64b1c;
  color: #fff;
}

.datepicker table tr td.active.active:hover:hover {
  color: #fff;
  background: #f64b1c;
}

.datepicker table tr td span {
  transition: all 0.4s;
}

.datepicker table tr td span:hover {
  background: #eaf3fc;
}

.datepicker table tr td span.active.active {
  background: #f64b1c;
  color: #fff;
}

.datepicker table tr td span.active.active:hover:hover {
  color: #fff;
  background: #f64b1c;
}

.footer-bar {
  font-size: 13px;
  padding: 20px 0;
  color: #6783b8;
}

.footer-bar a {
  color: #6783b8;
}

.footer-bar a:hover,
.footer-bar a:focus {
  color: #f64b1c;
}

.footer-copyright {
  display: block;
  padding: 15px 0 0;
}

.footer-links {
  padding: 15px 0;
  margin: 0 -5px;
}

.footer-links li {
  padding: 0 5px;
  display: inline-block;
  position: relative;
}

.footer-links li:after {
  position: absolute;
  right: -3px;
  top: 0;
  content: "|";
  color: #6783b8;
}

.footer-links li:last-child:after {
  content: "";
}
/** END */
.block-header-default button svg {
  color: rgb(246 75 28);
}
.content-header .btn-dual {
  padding: 5px 10px;
  min-width: auto;
  color: #2f4858;
}
.content-header .btn-dual .fa {
  margin: 0 !important;
}
.admin-sidenav .content-header {
  border-right: 1px solid rgb(98 70 57) !important;
}
.MuiTableSortLabel-root.MuiTableSortLabel-active {
  color: rgb(246 75 28) !important;
}
.MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active
  .MuiTableSortLabel-icon {
  color: rgb(246 75 28) !important;
  opacity: 1;
}
.MuiTableSortLabel-root:hover {
  color: rgb(246 75 28) !important;
}
.MuiTableSortLabel-root.MuiTableSortLabel-active {
  color: rgb(246 75 28) !important;
}

/* ========================== payment css ============================== */

body {
  overflow-x: hidden;
}
#border-btm {
  padding-bottom: 20px;
  margin-bottom: 0px;
  box-shadow: 0px 35px 2px -35px lightgray;
}
#test {
  margin-top: 0px;
  margin-bottom: 40px;
  border: 1px solid #ffe082;
  border-radius: 0.25rem;
  width: 60px;
  height: 30px;
  background-color: #ffecb3;
}
.active1 {
  color: #00c853 !important;
  font-weight: bold;
}
.bar4 {
  width: 35px;
  height: 5px;
  background-color: #ffffff;
  margin: 6px 0;
}
.list-group .tabs {
  color: #000000;
}
#menu-toggle {
  height: 50px;
}
#new-label {
  padding: 2px;
  font-size: 10px;
  font-weight: bold;
  background-color: red;
  color: #ffffff;
  border-radius: 5px;
  margin-left: 5px;
}
#sidebar-wrapper {
  min-height: 100vh;
  margin-left: -15rem;
  -webkit-transition: margin 0.25s ease-out;
  -moz-transition: margin 0.25s ease-out;
  -o-transition: margin 0.25s ease-out;
  transition: margin 0.25s ease-out;
}
#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}
#sidebar-wrapper .list-group {
  width: 15rem;
}
#page-content-wrapper {
  min-width: 100vw;
  padding-left: 20px;
  padding-right: 20px;
}
#wrapper.toggled #sidebar-wrapper {
  margin-left: 0;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #fff !important;
  border-color: #fff !important;
}

.card0 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.top-highlight {
  color: #00c853;
  font-weight: bold;
  font-size: 20px;
}
.form-card input,
.form-card textarea {
  padding: 10px 15px 5px 15px;
  border: none;
  border: 1px solid lightgrey;
  border-radius: 6px;
  margin-bottom: 25px;
  margin-top: 2px;
  width: 100%;
  box-sizing: border-box;
  font-family: arial;
  color: #2c3e50;
  font-size: 14px;
  letter-spacing: 1px;
}
.form-card input:focus,
.form-card textarea:focus {
  -moz-box-shadow: 0px 0px 0px 1.5px skyblue !important;
  -webkit-box-shadow: 0px 0px 0px 1.5px skyblue !important;
  box-shadow: 0px 0px 0px 1.5px skyblue !important;
  font-weight: bold;
  border: 1px solid skyblue;
  outline-width: 0;
}
input.btn-success {
  height: 50px;
  color: #ffffff;
  opacity: 0.9;
}
#below-btn a {
  font-weight: bold;
  color: #000000;
}
.input-group {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.input-group input {
  position: relative;
  margin-left: 1px;
  margin-right: 1px;
  border-radius: 6px;
}
.input-group label {
  position: absolute;
  height: 24px;
  background: none;
  border-radius: 6px;
  line-height: 48px;
  font-size: 15px;
  color: gray;
  width: 100%;
  font-weight: 100;
  padding-left: 25px;
}
input:focus + label {
  color: #1e88e5;
}
#qr {
  margin-bottom: 150px;
  margin-top: 50px;
}
/* claim section */

.timeline_area {
  position: relative;
  z-index: 1;
}
.single-timeline-area {
  position: relative;
  z-index: 1;
  padding-left: 180px;
}
.single-timeline-area .timeline-date {
  position: absolute;
  width: 180px;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -ms-grid-row-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding-right: 60px;
}
.single-timeline-area .timeline-date::after {
  position: absolute;
  width: 3px;
  height: 100%;
  content: "";
  background-color: #ebebeb;
  top: 0;
  right: 30px;
  z-index: 1;
}
.single-timeline-area .timeline-date::before {
  position: absolute;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background-color: #f14e0ffc;
  content: "";
  top: 50%;
  right: 26px;
  z-index: 5;
  margin-top: -5.5px;
}
.single-timeline-area .timeline-date p {
  margin-bottom: 0;
  color: #020710;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 500;
}
.single-timeline-area .single-timeline-content {
  position: relative;
  z-index: 1;
  padding: 20px 20px 15px;
  border-radius: 6px;

  margin-top: 15px;
  -webkit-box-shadow: 0 0.25rem 1rem 0 rgba(47, 91, 234, 0.125);
  box-shadow: 0 0.25rem 1rem 0 rgba(47, 91, 234, 0.125);
  border: 1px solid #ebebeb;
}
.single-timeline-area .single-timeline-content .timeline-icon {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  width: 30px;
  height: 30px;
  background-color: #f14e0ffc;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 30px;
  flex: 0 0 30px;
  text-align: center;
  max-width: 30px;
  border-radius: 50%;
  margin-right: 15px;
}
.single-timeline-area .single-timeline-content .timeline-icon i {
  color: #ffffff;
  line-height: 30px;
}
.single-timeline-area .single-timeline-content .timeline-text h6 {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
}
.single-timeline-area .single-timeline-content .timeline-text p {
  font-size: 13px;
  margin-bottom: 0;
}
.single-timeline-area .single-timeline-content:hover .timeline-icon,
.single-timeline-area .single-timeline-content:focus .timeline-icon {
  background-color: #020710;
}
.single-timeline-area .single-timeline-content:hover .timeline-text h6,
.single-timeline-area .single-timeline-content:focus .timeline-text h6 {
  color: #3f43fd;
}
.PULSE {
  /* Chrome, Safari, Opera */
  -webkit-animation: PULSE 3s infinite;

  /* Internet Explorer */
  -ms-animation: PULSE 3s infinite;

  /* Standard Syntax */
  animation: PULSE 3s infinite;
}

.fa2status-btn{
 margin-left: 20rem;
}

/* Chrome, Safari, Opera */
@-webkit-keyframes PULSE {
  0% {
    color: rgb(246 75 28 / 43%);
  }
  110% {
    color: rgb(246 75 28);
  }
}

/* Internet Explorer */
@-ms-keyframes PULSE {
  0% {
    color: rgb(246 75 28 / 43%);
  }
  110% {
    color: rgb(246 75 28);
  }
}

/* Standard Syntax */
@keyframes PULSE {
  0% {
    color: rgb(246 75 28 / 43%);
  }
  110% {
    color: rgb(246 75 28);
  }
}

/* .vesting-list {
} */
.vesting-list li {
  list-style: disc;
  margin-left: 14px;
  line-height: 18px;
  font-size: 11px;
  color: #f65b41;
}
.pre-whitelist {
  padding: 17px;
  background: #f64b1c;
  color: white;
}
.whitelist-header {
  background-color: #f6f5f5;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}
.white-container {
  /* height: 40vh; */
  overflow-y: auto;
  margin-bottom: 30px;
  /* border: 1px solid #f64b1cd6; */
  /* padding: 20px; */
  margin: 20px;
}
.white-container > ul li {
  list-style-type: decimal;
  padding: 5px;
  border-bottom: 2px solid #2f485808;
  /* border-bottom: 1px solid #f64b1cd6; */
}
.white-container > ul li:nth-child(even) {
  background: #2f485808;
}
.whitelist-input {
  padding: 20px;
  text-align: center;
}

.whitelist-input > input {
  width: 45%;
  padding: 5px;
  border: 1px solid #c4c4c4 !important;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-radius: 0px;
}
.whitelist-input .btn-primary.address-btn {
  width: 15%;
}
.btn-primary.address-btn {
  box-shadow: none !important;
}
.expand-claim:hover {
  background-color: #2f4858 !important;
}

/* .Toastify__toast-theme--light {
  text-align: center !important;
  font-size: 12px !important;
  color: #2f4858 !important;
  font-weight: normal;
  border: 1px solid #95abf1;
  font-family: "Poppins", sans-serif !important;
}
.Toastify__toast-body > div {
  font-size: 13px !important;
} */
#menu ul {
  list-style: none;
  color: black;
}
#menu li {
  color: black;
  display: inline;
  font-weight: bold;

  padding: 20px;
  font-size: 16px;
}
.invest-amou {
  margin-bottom: 10px;
  /* margin-top: 14px; */
  font-size: 15px;
  line-height: 18px;
}
.invest-name {
  color: #f64b1c;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}

.success-payment-popup .modal-content {
  border: none !important;
}
.success-payment-popup .modal-header {
  border: 2px solid #40a040;
  box-shadow: 0px 0px 5em #ccc;
}
.payment-item label:hover {
  border-color: #f64b1c;
}
.tranx-table thead {
  background: #6783b8;
}
.tranx-table thead th {
  color: white;
  padding: 10px 0px 10px 10px;
}

.vesting-list li {
  /* box-shadow: rgb(0 0 0 / 24%) 2px 2px 3px 0px; */
  padding: 4px 0px;
  /* border: 1px solid #00000033; */
  margin-bottom: 5px;
  margin-left: 0;
  list-style-type: none;
}
.vesting-list li em.ti {
  margin-right: 7px;
}
.buy-token-icon .payment-icon {
  margin: 0 auto;
}
.more-items .nav-link {
  color: #415076;
  padding-left: 25px;
}
.items-push label {
  display: flex;
  align-items: flex-end;
  max-width: 270px;
}
.makeStyles-paper60-2 {
  height: auto;
}
.address-btn {
  padding: 5px 10px;
  border: 2px solid #f64b1c;
  /* max-width:120px; */
}
.disbale-nav {
  color: red !important;
}
/* .single-timeline-area .vesting-list {
  max-height: 80px;
  overflow: auto;
  width: 50%;
}

.single-timeline-area ::-webkit-scrollbar {
  width: 5px;
}
.single-timeline-area  ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
.single-timeline-area  ::-webkit-scrollbar-thumb {
  background: #f64b1c; 
  border-radius: 10px;
}
.single-timeline-area  ::-webkit-scrollbar-thumb:hover {
  background: #2f4858; 
} */
.dashboard-toggle .toggle-line::after,
.dashboard-toggle .toggle-line::before,
.dashboard-toggle .toggle-line > span {
  content: "";
  position: absolute;
  background: currentColor;
  transition: all 0.2s;
  width: 24px;
  height: 2px;
  left: 0px;
  border-radius: 3px;
  transition: all ease-in-out 0.4s;
}

.dashboard-toggle .toggle-line::before {
  top: 8px;
}

.dashboard-toggle .toggle-line::after {
  bottom: 8px;
}

.dashboard-toggle .toggle-line > span {
  top: 50%;
  margin-top: -1px;
  width: 28px;
}
.site-toggle.toggle-nav .dashboard-toggle .toggle-line {
  background-color: transparent;
}
.dashboard-toggle.active .toggle-line {
  width: 30px;
  transition: all ease-in-out 0.4s;
}
.dashboard-toggle.active .active-line.toggle-line:nth-last-of-type(1) {
  transform-origin: center;
  /* transform: rotate(90deg); */
  /* height:0px;
  opacity: 0; */
  height: 0px;
}
.dashboard-toggle.active .active-line.toggle-line:nth-last-of-type(2) {
  opacity: 0;
}
.dashboard-toggle.active .toggle-line > span {
  opacity: 0;
  display: none;
}
.dashboard-toggle.active .toggle-line::before {
  transform: rotate(-45deg);
  top: 0px;
  transform-origin: top center;
}
.dashboard-toggle.active .toggle-line::after {
  transform: rotate(45deg);
  bottom: -1px;
  transform-origin: bottom center;
}
.filter-div {
  padding: 30px 10px 10px 10px !important;
  border-radius: 10px;
  width: 99%;
  margin: 0 auto;
  background: white;
  display: flex;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 5px;
  /* align-items: center; */
  /* justify-content: center; */
  align-items: flex-start;
}
.filter-div .css-iqd1hh-MuiButtonBase-root-MuiChip-root .MuiChip-deleteIcon {
  font-size: 13px;
}
.filter-input .MuiInputBase-input {
  padding: 10px !important;
  height: 25px;
}
.tag-filter .MuiOutlinedInput-root {
  /* height:55px; */
  font-size: 13px;
}
.tag-filter
  .css-16awh2u-MuiAutocomplete-root
  .MuiOutlinedInput-root
  .MuiAutocomplete-input {
  height: 30px !important;
  padding: 0px;
}
.tag-filter {
  /* width: 180px !important; */
  height: fit-content;
  display: flex;
}
.filter-input .btn.btn-primary {
  padding: 6px 12px;
  font-size: 12px;
}
.tag-filter > div > {
  background: #e8f0fe;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.tag-filter label {
  width: 100px;
  font-size: 12px;
  line-height: 20px;
}
.tag-filter .css-1q60rmi-MuiAutocomplete-endAdornment {
  top: 10px;
}
.filter-input .daterangepicker td.in-range {
  background-color: #fff5f6 !important;
}
.filter-input .MuiInputBase-input::placeholder {
  font-size: 12px;
}
.filter-input .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  top: -5px !important;
  font-size: 14px;
}
.password-form1 {
  width: 60%;
  margin: 0 auto;
}
.radio-form .css-vqmohf-MuiButtonBase-root-MuiRadio-root {
  margin-right: 10px !important;
}
.tab-card .card-header:first-child {
  padding: 35px;
  background: #fff;
  border-bottom: 0px;
}
.makeStyles-paper60-2,
.makeStyles-paper60-6 {
  height: 100% !important;
  top: 9% !important;
}
.email-inner-form > div {
  top: 9% !important;
  padding-top: 20px;
}
.email-inner-form > div {
  width: 70% !important;
}
.password-form {
  background-color: #ffff;
  min-height: 450px !important;
  /* height: 450px !important; */
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  overflow-y: auto;
}
.password-form.radio-form {
  overflow-x: hidden;
}
.ico-tabs {
  padding-top: 25px;
}
.ico-tabs .tab-card {
  background: #f4cac991;
  padding: 10px 24px;
  border-radius: 5px;
  /* border-bottom: 0px; */
  font-weight: 600;
  color: #f30f04;
  /* border: 2px solid #f30f04; */
  width: 100% !important;
  /* transform: scale(0.9, 0.9); */
  transition: all ease-in-out 0.4s;
}
.ico-tabs .tab-card:hover {
  transform: scale(0.9, 0.9);
  box-shadow: 5px 5px 30px 15px rgb(255 255 255),
    -5px -5px 30px 15px rgb(255 255 255);
}
.ico-tabs svg {
  color: #fe5748;
  font-size: 35px;
}
.ico-tabs span {
  position: unset !important;
}
.ico-tabs .tab-icon svg {
  width: 25px;
  height: 25px;
  color: #fff;
}
.tab-icon {
  background-color: #f30f04;
  padding: 10px;
  color: #fff;
  border-radius: 50%;
  margin-bottom: 10px !important;
}
.ico-form .common-cms-pg.block-content {
  padding-top: 0px !important;
}
.ico-form .MuiSvgIcon-colorError {
  cursor: pointer;
}
.filter-input .App {
  text-align: start !important;
}
.text-green {
  color: #7eae35 !important;
}
/* .token-label .css-1auycx3-MuiAutocomplete-root div span {
  margin-left: 0px !important;
  margin-right: auto;
} */
.thumbnail-slider-wrap {
  margin-top: 15px;
}
.thumbnail-slider-wrap .slick-track .slick-slide {
  text-align: center;
}
.thumbnail-slider-wrap .slick-track .slick-slide img {
  width: 70%;
}
.tab-btn {
  height: 36px;
  padding: 0 0 0 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: start;
  font-size: 11px;
  color: #fff;
  border-color: transparent;
  transition: all ease-in-out 0.4s;
}
.slider-wrapper .tab-btn {
  border-radius: 50px;
}
.slider-wrapper .tab-btn-1 {
  background-color: #28286e;
  color: #fff;
}
.thumbnail-slider-wrap .tab-btn-row {
  padding-bottom: 5px;
}
.thumbnail-slider-wrap .accordion-content {
  text-align: start;
  /* border: 1px solid #f75d33; */
  border-radius: 5px;
  padding: 10px 0px 0px 0px;
}
.thumbnail-slider-wrap .accordion-slider .slick-track {
  margin-bottom: 40px;
}
.thumbnail-slider-wrap .accordion-slider .slick-prev,
.thumbnail-slider-wrap .accordion-slider .slick-next {
  background-color: transparent;
  top: 100%;
  background: #f64b1c;
  border: none;
  border-radius: 3px;
  color: #fff;
  padding: 0px 30px;
  height: 30px;
  transition: all ease-in-out 0.4s;
}
.thumbnail-slider-wrap .accordion-slider .slick-prev:hover,
.thumbnail-slider-wrap .accordion-slider .slick-next:hover {
  box-shadow: 1px 2px 5px rgb(0 0 0 / 22%);
}
.thumbnail-slider-wrap .accordion-slider .slick-prev::before {
  content: "Back";
  font-family: "Poppins";
  color: #fff !important;
  font-size: 12px !important;
  opacity: 0.9 !important;
  display: flex;
  justify-content: center;
}
.thumbnail-slider-wrap .accordion-slider .slick-next::before {
  content: "Next";
  /* background-image: url('../images/next-arrow.svg'); */
  font-family: "Poppins";
  color: #fff !important;
  font-size: 12px !important;
  opacity: 0.9 !important;
  display: flex;
  justify-content: center;
}
.thumbnail-slider-wrap .accordion-slider .slick-prev:hover,
.thumbnail-slider-wrap .accordion-slider .slick-next:hover {
  cursor: pointer;
}
.thumbnail-slider-wrap .accordion-slider.slick-slider .slick-prev::before,
.thumbnail-slider-wrap .accordion-slider.slick-slider .slick-next::before {
  font-size: 22px;
  color: #6783b8;
}
.thumbnail-slider-wrap .accordion-slider.slick-slider .slick-prev {
  left: auto;
  right: 10% !important;
}
.thumbnail-slider-wrap .slick-track .slick-slide .tab-btn h4 span {
  color: #fff;
}
.thumbnail-slider-wrap
  .slick-track
  .slick-slide.slick-current
  .tab-btn
  h4
  span {
  color: #fff;
}
.thumbnail-slider-wrap .slick-track .slick-active:first-child .tab-btn {
  background: #28286e;
  position: relative;
  z-index: 5 !important;
}
.thumbnail-slider-wrap .slick-track .slick-slide.slick-active:first-of-type {
  position: relative;
  z-index: 5 !important;
}
.modal-content.modal-content-width {
  width: 60%;
}
.thumbnail-slider-wrap .slick-track .slick-slide ul{padding-left: 20px; list-style: circle;}
.thumbnail-slider-wrap .slick-track .slick-slide ul li{list-style: circle;}
.thumbnail-slider-wrap .slick-track .slick-slide ul li::marker{color: #F64B1C;}
.thumbnail-slider-wrap .slick-track .slick-slide p{margin-top: 0;}
/* -------------------- */

.thumbnail-slider-wrap .tab-btn-row .slick-track {
  width: 100% !important;
  display: flex;
  transform: none !important;
}
.thumbnail-slider-wrap .tab-btn-row .slick-track .slick-slide:nth-child(1) {
  z-index: 5;
}
.thumbnail-slider-wrap
  .tab-btn-row
  .slick-track
  .slick-slide:nth-child(1)
  .tab-btn {
  padding-left: 15px;
}
.thumbnail-slider-wrap .tab-btn-row .slick-track .slick-slide {
  text-align: center;
  width: auto !important;
  width: 33% !important;
}
.thumbnail-slider-wrap .tab-btn-row .slick-track .slick-slide .tab-btn {
  background: #ff843e;
}
.thumbnail-slider-wrap .tab-btn-row .slick-track .slick-slide:nth-child(2) {
  z-index: 4;
}
.thumbnail-slider-wrap
  .tab-btn-row
  .slick-track
  .slick-slide:not(:first-of-type) {
  margin-left: -50px;
}
.thumbnail-slider-wrap .tab-btn-row .slick-track .slick-slide.slick-current {
  width: 40% !important;
  transition: all ease-in-out 0.4s;
}
.thumbnail-slider-wrap
  .tab-btn-row
  .slick-track
  .slick-slide.slick-current
  .tab-btn {
  display: flex !important;
  justify-content: flex-start !important;
  z-index: 4 !important;
  position: relative;
  background-image: linear-gradient(90deg, #fe4350 0, #ff6c3f) !important;
  border-color: transparent;
  transition: all ease-in-out 0.3s;
}
.thumbnail-slider-wrap .tab-btn-row .slick-track .slick-slide:nth-child(3) {
  z-index: 3;
}
/* .thumbnail-slider-wrap .tab-btn-row .slick-track .slick-slide:nth-child(odd) .tab-btn {
  background:#4a5157;
}
.thumbnail-slider-wrap .tab-btn-row .slick-track .slick-slide:nth-child(even) .tab-btn {
  background:#31404a;
} */
/* .thumbnail-slider-wrap .tab-btn-row .slick-track .slick-slide:nth-child(1) .tab-btn {
  background:linear-gradient(90deg,#fe4350 0,#ff6c3f) !important;;
} */
.thumbnail-slider-wrap
  .tab-btn-row
  .slick-track
  .slick-slide:nth-child(4)
  .tab-btn {
  background: #900C3F;
}
.thumbnail-slider-wrap
  .tab-btn-row
  .slick-track
  .slick-slide:nth-child(3)
  .tab-btn {
  background: #C70039;
}
.thumbnail-slider-wrap
  .tab-btn-row
  .slick-track
  .slick-slide:nth-child(2)
  .tab-btn {
  background: #E34A27;
}
.thumbnail-slider-wrap .tab-btn-row .slick-track .slick-slide:nth-child(4) {
  z-index: 2;
}
.upload-btn div.bg-primary {
  box-shadow: none !important;
  background-color: #7488b8 !important;
  padding: 10px 10px;
  height: 35px;
  border-radius: 5px !important;
  margin: auto 0px;
}
.upload-btn div {
  align-items: center;
}
.upload-btn svg {
  font-size: 14px;
}
/* .range-bar .css-187mznn-MuiSlider-root{
  color: #2f4858;
} */
.range-bar span {
  color: #2f4858;
  max-width: 200px;
}
.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: #f64b1c !important;
}
.dashboard-card {
  justify-content: flex-start;
}
.dashboard-card .cards-nth-color:nth-of-type(1) {
  background: #f64b1c linear-gradient(135deg, #f64b1c, #f64b1c) !important;
}
.dashboard-card .cards-nth-color:nth-of-type(2) {
  background: #f6be26 linear-gradient(135deg, #f6be26, #f6be26) !important;
}
.dashboard-card .cards-nth-color:nth-of-type(3) {
  background: rgba(2, 170, 176, 0.5)
    linear-gradient(135deg, rgba(2, 170, 176, 0.5), rgba(0, 205, 172, 0.5)) !important;
}
/* .dashboard-card .cards-nth-color:nth-of-type(3){
  background:#8a16ff80!important;
} */
.dashboard-card .cards-nth-color:nth-of-type(4) {
  /* background:#5856d6 linear-gradient(135deg,#5856d6,#5856d6)!important; */
  background: #002868 !important;
}
.dashboard-card .cards-nth-color:nth-of-type(5) {
  background: #0000004d linear-gradient(135deg, #0000004d, #0000004d) !important;
}
.dashboard-card .cards-nth-color:nth-of-type(6) {
  background: #f64b1c linear-gradient(135deg, #9284fa, #9284fa) !important;
}
.dashboard-card .cards-nth-color:nth-of-type(7) {
  /* background:#002868 linear-gradient(135deg,#002868,#002868)!important; */
  background: #df3b64 !important;
}
.dashboard-card .cards-nth-color:nth-of-type(8) {
  /* background:#002868 linear-gradient(135deg,#002868,#002868)!important; */
  background: #ff5819 !important;
}
.b-color-tuple table thead tr {
  background-color: #f6f5f5 !important;
}
.b-color-tuple table tbody tr:nth-of-type(odd) {
  background-color: #f9f9f9 !important;
}
.b-color-tuple table tbody tr:nth-of-type(even) {
  background-color: #f6f5f5 !important;
}
/* STYLE BY ME */
/* CHAT BOX STYLE */
.container.token-container {
  margin-bottom: 0px;
}
.chat-box {
  position: absolute;
  right: 60px;
  bottom: 0px;
  display: none;
}
.chat-box .chat-box-style {
  width: 62px;
  height: 56px;
  box-shadow: 0px 0px 13px 2px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  background-color: #f64b1c;
  display: flex;
  align-items: center;
  justify-content: center;
}
.theme-shadow {
  box-shadow: 0 0 50px rgb(254 67 80 / 10%);
}
.rcv-tokens {
  background: #ffffff;
  box-shadow: 0px 0px 23px 2px rgba(0, 0, 0, 0.1);
}
.spend-tokens {
  background: #f64b1c;
  color: #fff;
  border-color: #fff;
}
.rcv-tokens,
.spend-tokens {
  border-radius: 5px;
  padding: 50px;
  height: 100%;
}
.circle-tick li {
  position: relative;
  padding-left: 30px;
  margin-bottom: 10px;
  color: #2f4858;
  font-size: 16px;
  line-height: 25px;
}
.circle-tick li::before {
  content: "✔";
  position: absolute;
  left: 0;
  top: 2px;
  width: 20px;
  height: 20px;
  font-size: 13px;
  font-weight: 400;
  text-align: center;
  color: #f64b1c;
  border: 1px solid #f64b1c;
  background: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.spend-tokens .circle-tick li {
  color: #fff;
}
.spend-tokens .circle-tick li::before {
  color: #fff;
  border: 1px solid #fff;
  background: transparent;
}
.crypto-whitepaper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.crypto-center {
  display: flex;
}
.crypto-whitepaper .crypto-center > div {
  margin-right: 35px;
}
.detail-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  display: block;
  margin-bottom: 20px;
}
/* ROADMAP */
.ico-roadmap {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(129px, 1fr));
  grid-template-rows: repeat(1, auto);
  gap: 1.75rem;
  justify-content: center;
  /* width: 129px; */
}
.roadmap-plan {
  border-left: 2px solid #fff;
}
.roadmap-header {
  padding: 18px;
  background: linear-gradient(90deg, #ff833e 0%, #f64b1c 100%);
}
.roadmap-header span {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #fff;
}
.roadmap-body {
  padding: 18px;
}
.roadmap-body span {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
}
.roadmap-body p {
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
}
/* FOOTER */
footer {
  padding: 111px 0px 0px 0px;
}
.block-footer {
  padding-bottom: 70px;
}
.ui-shape-footer.ui-shape {
  z-index: 2;
  overflow: inherit;
}
.betts-img {
  max-width: 556px;
  margin-left: auto;
}
/* block-content ggg */
.block-content.block-content-full.ggg {
  padding: 0px 30px 30px 30px;
}

.range-bar .MuiBox-root{
  width: auto;
}
.range-bar .btn-secondary{
  margin-left: 20px;
}
.filter-input .btn-primary{
  margin-top: 5px;
  margin-left: 10px;
}
/* error mesbackground: #f64b1d33;
    padding: 10px;
    border-radius: 5px;
    color: red;
    margin-top: 39px;sage */
.stripe-error {
  background: #f64b1d33;
    padding: 10px;
    border-radius: 5px;
    color: red;
    margin-top: 39px;
}
.investor-banner{
  background-image: linear-gradient(90deg, #ff843e 0, #ff6c3f);
  padding: 25px 30px;
  border-radius: 15px;
  margin-bottom: 40px;
  position: relative;
  min-height: 250px;
  height: 100%;
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  overflow: hidden;
  align-items: center;
  min-height: 330px;
  height: 100%;
}
.investor-banner-img img{border-radius: 10px;}
.investor-banner::before, .investor-banner::after{
  position: absolute;
  content: "";
  border-radius: 50%;
  /* background: rgba(255, 255, 255, 0.09); */
}
.investor-banner::before{
  height: 144px;
  width: 144px;
  bottom: -55px;
  left: -55px;
  background: white;
  opacity: 0.15;
}
.investor-banner::after{
  height: 144px;
  width: 144px;
  top: -55px;
  right: -55px;
  background: yellow;
  opacity: 0.15;
}

.investor-banner h2{color:#fff}
.investor-banner p{color:#e3e3e3}

.logo-width{
  width: 171px;
  margin: 0 auto 25px;
}
/* Setup wallet accordion */
.wrapAccordion{
  display: flex;
  align-items: start;
  justify-content: space-between;
}
.wrapAccordion .closePlus{
  width: 25px;
  height: 25px;
  border-radius: 50%;
  color: #f64b1c;
  font-size: 18px;
  box-shadow: 0 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* MEDIA QURIES */

@media (min-width: 992px) {
  .container {
    max-width: 1050px;
  }
  .user-sidebar {
    width: 240px;
    position: relative;
    flex-grow: 0;
    flex-shrink: 0;
    box-shadow: none;
    height: auto;
    left: 0;
    padding: 0;

    transition: unset;
    display: block;
    overflow: visible;
  }
  .user-sidebar-overlay {
    display: none;
  }
  .user-sidebar:before {
    display: none;
  }
  .user-dashboard {
    padding-top: 110px;
  }
  .user-content {
    margin-left: 30px;
  }
}
@media (min-width: 768px) {
  .alert-box {
    padding: 7px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .alert-txt {
    padding-bottom: 0;
    font-size: 14px;
    justify-content: flex-start;
  }
  #sidebar-wrapper {
    margin-left: 0;
  }
  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }
  #wrapper.toggled #sidebar-wrapper {
    margin-left: -15rem;
    display: none;
  }
  .token-info {
    width: 58%;
    flex-grow: 0;
  }
  .progress-card {
    padding: 20px;
  }
  .progress-info li {
    font-size: 14px;
  }
  .progress-hcap > div,
  .progress-scap > div,
  .progress-psale > div,
  .progress-percent > div {
    font-size: 11px;
  }
  .nav-tabs-line .nav-item {
    padding: 0 40px 0 0;
  }
  .nav-tabs-line .nav-link {
    font-size: 14px;
  }
  .footer-bar {
    padding: 40px 0 20px;
  }
  .footer-copyright {
    padding: 15px 0;
  }
}
@media (min-width: 660px) {
  .modal-content .modal-close {
    top: -22px;
    right: -22px;
  }
}
@media (min-width: 640px) {
  .modal-dialog {
    /* max-width: 630px;
    min-width: 630px; */
  }
}
@media (min-width: 576px) {
  .modal-dialog {
    /* max-width: 100%;
    min-width: 100%; */
  }
  .user-dashboard {
    padding-top: 90px;
  }
  .user-panel {
    padding: 30px;
  }
  .tile-info {
    font-size: 20px;
  }
  .tile-info-list span {
    font-size: 24px;
  }
  .info-card-bordered {
    padding: 25px;
  }
  .token-info {
    padding: 20px 30px 20px 110px;
  }
  .token-bonus {
    padding-left: 0;
  }
  .status {
    padding: 50px;
  }
  .status-sm {
    padding: 20px;
  }
  .nav-tabs-bordered .nav-item {
    padding: 0 15px;
    margin-bottom: 25px;
  }
  .nav-tabs-bordered .nav-link {
    font-size: 14px;
  }
  .nav-tabs-icon {
    width: 64px;
  }
  .payment-calculator {
    margin: 0 -24px;
  }
  .payment-calculator > div {
    padding: 0 24px;
    width: 50%;
  }
  .payment-calculator .ti {
    position: absolute;
    bottom: 8px;
    left: 50%;
    transform: rotate(-90deg) translate(50%, -50%);
    margin-bottom: 0;
  }
  .payment-item {
    margin-bottom: 30px;
  }
  .payment-item label {
    padding: 20px 20px 10px;
    display: block;
  }
  .payment-item label span {
    padding-left: 0;
  }
  .payment-item > span {
    position: static;
    margin-top: 2px;
    transform: translateY(0);
  }
  .payment-icon img {
    width: 50px;
  }
  .payment-icon [class*="fa-"] {
    height: 50px;
    width: 50px;
    line-height: 50px;
    font-size: 20px;
  }
  div.dataTables_wrapper div.dataTables_filter {
    text-align: left;
  }
  div.dataTables_wrapper div.dataTables_filter input {
    text-align: left;
  }
  div.dataTables_wrapper div.dataTables_length {
    text-align: right;
  }
  div.dataTables_wrapper div.dataTables_info {
    text-align: right;
  }
  div.dataTables_wrapper div.dataTables_paginate ul.pagination {
    justify-content: flex-start;
  }
  div.dataTables_wrapper > .row > .col-12 {
    padding-left: 15px;
    padding-right: 15px;
  }
  .tranx-popup {
    padding: 10px;
  }
  .tranx-amount,
  .tranx-from {
    display: table-cell;
  }
  .tranx-status {
    width: 25px;
  }
  .tranx-status .ti {
    height: 30px;
    width: 30px;
    line-height: 28px;
    font-size: 14px;
  }
  .tranx-purchase-info li {
    display: flex;
  }
  .tranx-purchase-head {
    width: 185px;
  }
  .tranx-purchase-des {
    border-top: none;
    border-left: 1px solid rgba(211, 224, 243, 0.5);
  }
  .data-table tbody td {
    font-size: 12px;
    padding: 18px 0;
  }
  .data-table tbody td div > span,
  .data-table tbody td > span {
    font-size: 14px;
  }
  /* .data-table tbody td:first-child {
    padding-left: 20px; 
  } */
  .activity-time {
    min-width: 120px;
  }
  .activity-device {
    display: block;
  }
  .refferal-tokens,
  .refferal-channel {
    display: block;
  }
}
@media (min-width: 575px) {
  .kyc-upload-img {
    padding: 0 30px 0;
  }
  .kyc-popup {
    padding: 60px;
  }
  .from-step-heading {
    padding: 30px;
  }
  .from-step-content {
    padding: 30px;
  }
}
@media (min-width: 420px) {
  .nav-tabs-line .nav-item {
    padding: 0 30px 0 0;
  }
  .nav-tabs-line .nav-link {
    font-size: 13px;
    letter-spacing: 0em;
  }
}
@media (min-width: 419px) and (max-width: 575px) {
  .payment-list .col-sm-6 {
    width: 50%;
  }
  .payment-item > span {
    position: static;
    margin-top: 2px;
    transform: translateY(0);
  }
}
@media only screen and (max-width: 575px) {
  .single-timeline-area .timeline-date {
    width: 100px;
  }
  .single-timeline-area .single-timeline-content {
    padding: 20px;
  }
  .site-logo img {
    width: 100px;
    height: auto;
  }
  .single-timeline-area {
    padding-left: 100px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .token-box-girth {
    max-width: 75%;
    margin: 0 auto;
  }
}
@media (min-width: 767px) and (max-width: 1199px) {
  .token-box-girth.FlipClock .flipUnitContainer {
    height: 40px;
  }
}
@media (max-width: 992px) and (min-width: 576px) {
  .team-popup-main .main-popup-card {
    height: 90% !important;
    overflow-y: scroll !important;
    margin-bottom: 10px !important;
    top: 5%;
    bottom: 5%;
  }
  /* .cms-col-mb{
    margin-top: 55px;
  } */
  .table-kyc-container > div {
    margin-top: 25px;
  }
  .table-kyc-container #manage-users-modal,
  .inner-form-kyc-view > div,
  .table-kyc-container #manage-users-modal,
  .inner-form-kyc-view > div {
    max-height: 75% !important;
  }
}
@media (min-width: 1399px) and (max-width: 1920px) {
  .container.token-container {
    margin-bottom: 0px;
  }
  .chat-box {
    bottom: 0px;
  }
}
@media (max-width: 1499.98px) {
  .email-formating ul li span:last-child{font-size: 12px;}
  .email-formating ul li{margin: 0 0 10px 0;}
  .email-formating ul{display: block !important;}
}
@media (max-width: 1399.98px) {
  .logo-width {
    width: 150px;
    margin: 0 auto 20px;
}
  .filter-loader {
    max-width: 180px;
  }
  .icnBox {
    left: 94px;
    /* top: -90px; */
  }
  .orange-email > div > img {
    width: 101px;
    margin-top: 35px;
  }
  .block-content.block-content-full.ggg
    .md-setting.block-content
    .items-push
    > div {
    margin-bottom: 0px !important;
  }
  .block-content.block-content-full.ggg .md-setting.block-content {
    padding: 0px 5px !important;
  }
  .content-heading {
    margin-bottom: 5px;
    padding-top: 10px;
    padding-bottom: 5px;
    font-size: 17px;
  }
  .container.token-container {
    margin-bottom: 50px;
  }
  .email-form header,
  .table-kyc-modal header,
  .table-kyc-view header,
  .table-view-modal header {
    width: 70% !important;
  }
  .email-form > .email-inner-form > div {
    width: 70% !important;
    height: 550px !important;
    overflow-x: hidden !important;
  }
  .tranx-payment-copy {
    height: 30px;
    padding: 10px 0;
    right: 5px;
    top: 5px;
    width: 30px;
    font-size: 13px;
  }
  .dropdown-flex-col a,
  .dropdown-flex-col .MuiMenuItem-root {
    padding: 5px 15px !important;
    font-size: 14px;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  /* .password-form > div{
    height:550px !important;
  } */
  h2 {
    line-height: 25px;
  }
  .switch-btn {
    width: 42px !important;
    height: 23px !important;
  }
  .switch-btn span {
    width: 20px !important;
    height: 20px !important;
  }
  /* .items-push{
    padding-top:100px;
  } */
  .nav-main-item .nav-main-link svg {
    width: 16px;
    margin-right: 13px !important;
  }
  .nav-main-link {
    font-size: 13px;
    line-height: 20px;
    padding: 10px 0 10px 15px;
  }
  .nav-main-link:hover,
  .nav-main-link.active {
    border-left: #fff 4px solid;
  }
  .admin-sidenav {
    width: 215px !important;
  }
  .admin-sidevar-content {
    padding-left: 215px !important;
  }
  .site-logo a {
    width: 100px;
  }
  .site-logo-in-mobile img {
    width: 25px;
  }
  .site-logo a img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .content-header .btn-dual {
    padding: 2px 5px;
  }
  .content-header .btn-dual i {
    font-size: 12px;
  }
  .transaction-modal .modal-content {
    width: 576px !important;
  }
  .section-b,
  .section {
    padding-bottom: 50px;
  }
  .section-t,
  .section {
    padding-top: 50px;
  }
  .badge-presale {
    height: 30px;
    padding: 4px 9px 4px 9px;
    margin-left: 6px;
    font-size: 11px;
    line-height: 21px;
    letter-spacing: 2.5px;
  }
  .payment-summary-amount {
    font-size: 13px;
    line-height: 17px;
  }
  .payment-summary {
    border-radius: 4px;
    border: 1px solid #dce6f5;
    padding: 15px 17px 0;
  }
  .user-panel-subtitle {
    font-size: 13px;
    margin-bottom: 5px;
  }
  .status-text {
    font-size: 15px;
  }
  .tranx-payment-info > [class*="fa"] {
    font-size: 14px;
    line-height: 11px;
  }
  .tranx-payment-address {
    padding: 9px 40px;
    font-size: 13px;
  }
  .user-panel p {
    font-size: 12px;
    line-height: 19px;
  }
  .accordion-content p {
    font-size: 12px !important;
    line-height: 19px !important;
  }
  .tile-title {
    font-size: 12px;
    line-height: 16px;
  }
  .user-panel .tokenSale-Table .table .bonusWeeks span.lightText {
    font-size: 12px;
  }
  .tiny-tuple-text {
    font-size: 13px !important;
  }
  .single-timeline-area .single-timeline-content .timeline-text p {
    font-size: 12px;
  }
  .btn-md.btn-grad {
    padding: 8px 22px;
    min-width: 120px;
  }
  .user-header-h5 {
    font-size: 19px;
    line-height: 23px;
  }
  /* .connect-wallet {
    font-size: 12px;
    line-height: 20px;
    padding: 4px 9px;
  } */
  .connect-wallet svg {
    width: 17px;
  }
  .user-panel .user-blnc {
    margin-bottom: 20px;
  }
  .block-title span {
    font-size: 19px;
    line-height: 32px;
  }
  /* .block-header {
    padding: 10px 20px;
  } */
  .card.card-airdrop .card-header {
    padding: 12px;
  }
  .card.card-airdrop .card-header ul li {
    display: flex;
    justify-content: space-between;
  }
  .MuiInputBase-input {
    height: 15px !important;
    font-size: 13px !important;
  }
  .profile-handler {
    padding: 40px;
  }
  .user-dropdown-balance h3 {
    font-size: 14px;
  }
  .user-dropdown-links li a {
    font-size: 13px;
  }
  .user-dropdown-balance {
    padding: 12px 20px 10px 20px;
  }
  ul:not(.btn-grp) {
    margin-bottom: 5px;
  }
  .btn {
    padding: 8px 17px;
    font-size: 13px;
    line-height: 20px;
  }
  .inner-form-kyc-view > div {
    height: 550px !important;
  }
  #manage-users-modal {
    padding-top: 10px;
    height: 550px !important;
  }
  .payment-page h2 {
    font-size: 30px;
    line-height: 33px;
  }
  .payment-page p {
    font-size: 14px;
  }
  .token-smartag {
    min-width: 205px;
    font-size: 12px;
  }
  .makeStyles-header,
  .makeStyles-paper {
    max-width: 992px !important;
  }
  .sun-editor-editable a.on {
    font-size: 16px !important;
  }
  .table td.table-btn-group [type="button"] {
    min-width: 30px;
    min-height: 30px;
  }
  .table td.table-btn-group svg {
    width: 18px;
    height: 18px;
  }
  .makeStyles-paper60-6 {
    height: 550px !important;
    width: 70% !important;
  }
  .user-icon-nav li a {
    font-size: 14px;
    line-height: 18px;
  }
  .user-icon-nav li a .ti {
    font-size: 16px;
  }
  .check-icon svg {
    width: 22px;
  }
  .invest-amou {
    margin-bottom: 5px;
    /* margin-top: 12px; */
    font-size: 14px;
  }
  .invest-name {
    /* font-size: 17px; */
  }
  /* .tile-light {
    border: 1px solid #dce6f5;
    padding: 25px 15px 18px;
  }
  .tile-info {
    font-size: 21px;
} */
  .accordion-item-s2 .accordion-title {
    line-height: 20px;
    padding: 17px 54px 11px 30px;
    font-size: 14px;
  }
  .accordion-icon:before,
  .accordion-icon:after {
    line-height: 0px;
    font-size: 10px;
  }
  .accordion-icon {
    top: calc(50% + 0px);
    height: 30px;
    width: 30px;
  }
  /* RCV/SPEND TOKENS */
  .circle-tick li {
    font-size: 14px;
    line-height: 20px;
  }
  /* FOOTER */
  footer {
    padding: 95px 0px 0px 0px;
  }
  .wgs {
    margin-bottom: 15px;
  }
  .email-inner-form > div {
    top: 12% !important;
  }
  .error1,
  .error {
    font-size: 12px;
  }
  .roadmap-header,
  .roadmap-body {
    padding: 13px;
  }

}
@media (max-width: 1199px) {
  /* .about-pt{
    padding-top:120px;
  } */
  .range-bar span {
    max-width: 150px;
  }
  .ico-tabs .tab-card {
    width: 100% !important;
    height: 100%;
  }
  .chat-box .chat-box-style {
    width: 45px;
    height: 45px;
    border-radius: 5px;
  }
  .chat-box .chat-box-style img {
    width: 20px;
  }
  /* .ico-tabs .px-tab{
  padding-left:5px !important;
  padding-left:5px !important;
} */
  /* BANNER */
  .banner-social-mobile {
    display: block;
  }
  .banner-social-desktop {
    display: none;
  }
}
@media (max-width: 992px) {
  .user-wraper {
    padding-top: 145px;
  }
  .invest-name{font-size: 14px; font-weight: 700;}
  /*  */
  .icnBox {
    left: 75px;
    /* top: -83px; */
    width: 200px;
  }
  .msg-icn {
    padding: 6px;
  }
  .toggleBtn{
    display: block;
  }
  .admin-sidevar-content {
    padding-left: 0px !important;
  }
  .mini-px {
    padding-left: 215px;
  }
  .mobile-icon {
    display: block;
  }
  /* DESKTOP-HOME */
  .cpn-desktop {
    display: none;
  }
  /* MOBILE-HOME */
  .cpn-mobile {
    display: block;
  }
  /* BANNER */
  .banner-social-vertical {
    position: unset;
  }
  .custom-tab .custsom-tab-btn {
    padding: 8px 10px;
  }
  .table-kyc-modal {
    width: 540px !important;
  }
  #modal-header,
  #manage-users-modal {
    width: 540px !important;
  }
  #manage-users-modal,
  .inner-form-kyc-view > div {
    height: 400px !important;
  }
  .ico-tabs .tab-card {
    width: 300px !important;
  }
  .user-icon-nav {
    padding-bottom: 15px;
  }
  .user-nav {
    padding: 15px 0px;
  }
  .user-sidebar-sap {
    width: 25px;
    height: 2px;
  }
  .makeStyles-header80-5,
  .makeStyles-paper80-4 {
    max-width: 767px !important;
  }
  .sun-editor-editable a.on {
    font-size: 14px !important;
  }
  .user-icon-nav {
    padding-left: 30px;
    padding-right: 30px;
  }
  .user-icon-nav li a {
    font-size: 13px;
  }
  .user-icon-nav li a .ti {
    width: 25px;
    margin-right: 8px;
  }
  .zinnia-sidebar {
    min-height: 100% !important;
  }
  .thumbnail-slider-wrap .accordion-slider .slick-prev,
  .thumbnail-slider-wrap .accordion-slider .slick-next {
    padding: 0px 35px;
    height: 33px;
  }
  .thumbnail-slider-wrap .accordion-slider .slick-track {
    margin-bottom: 15px;
  }
  .user-dropdown-btns {
    padding: 5px 5px;
  }
  .user-dropdown-btns .btn {
    padding: 5px 10px;
    font-size: 11px;
    line-height: 17px;
  }
  .transaction-report-graph span,
  .transaction-report-graph .apexcharts-legend-text {
    font-size: 10px !important;
  }
  .transaction-report-graph span,
  .apexcharts-legend-marker {
    width: 14px !important;
    height: 14px !important;
  }
  /* .admin-sidevar-content {
  padding-left: 75px  !important;
} */
  .whitelist-input > input {
    width: 55%;
  }
  .whitelist-input .btn-primary.address-btn {
    width: 20%;
  }
  .FlipClock .flipUnitContainer {
    height: 45px !important;
  }
  .token-box-girth {
    width: 80%;
    margin: 0 auto;
  }
  .ui-shape-s2.ui-shape:before {
    width: 150px;
    height: 150px;
    left: 19%;
    top: 2%;
  }
  .betts-img {
    margin: auto;
  }
  /* FOOTER */
  footer {
    padding: 50px 0px 0px 0px;
  }
  .ui-shape-footer.ui-shape::before {
    left: 11%;
    top: -31px;
    height: 140px;
    width: 140px;
  }
  .ui-shape-footer.ui-shape::after {
    right: -5%;
    bottom: -25%;
  }
}
@media (max-width: 767.98px) {
  .logo-width {
    width: 135px;
  }
  .investor-banner{padding: 20px 15px;}
  .investor-banner-text{margin-bottom: 30px;}
  .investor-banner-img{
    max-width: 300px;
    width: 100%;
    margin: 0 auto;
  }
  .content-heading {
    font-size: 17px !important;
    line-height: 22px !important;
  }
  .email-inner-form > div {
    top: 13% !important;
  }
  .block-content.block-content-full.ggg
    .md-setting.block-content
    .items-push
    > div {
    margin-bottom: 0px !important;
  }
  .block-content.block-content-full.ggg .md-setting.block-content {
    padding: 0px 5px !important;
  }
  .block-content .items-push > div {
    margin-bottom: 10px;
  }
  .block-content.block-content-full {
    padding: 10px 10px !important;
  }
  .benifit-ball-mobile {
    display: none;
  }
  .w-45 {
    width: 100%;
  }
  .common-cms-pg.block-content .tab-ico-input input {
    height: 20px !important;
  }
  .form-px {
    padding-right: 20px;
    padding-left: 20px;
  }
  .on {
    font-size: 12px !important;
    border-radius: 4px;
    padding: 5px 9px !important;
  }
  .orange-email > div {
    padding: 15px 0px !important;
  }
  .orange-email > div > img {
    width: 85px;
    margin-top: 20px;
  }
  .apexcharts-legend > div {
    margin: 2px 25px !important;
  }
  .w-md-45 {
    width: 100%;
  }
  .checkbox-tiny {
    padding: 0px !important;
  }
  .cms_cards input.md-h-input {
    height: 15px !important;
  }
  .kyc-upload-img img {
    width: 100px;
    height: 80px;
  }
  #modal-header,
  #manage-users-modal,
  .inner-form-kyc-view > div,
  .email-form header,
  .table-kyc-modal header,
  .table-kyc-view header,
  .table-view-modal header,
  .email-msg-modal > div,
  .makeStyles-paper60-2 {
    width: 90% !important;
  }
  .ico-input-w45 {
    width: 100%;
    margin-bottom: 10px;
  }
  /* .password-form > div{
    height:280px !important;
  } */
  .whitelist-input .btn-primary.address-btn {
    height: 35px;
  }
  .row-reverse {
    flex-direction: row-reverse;
  }
  .transaction-modal .modal-content {
    width: 480px !important;
  }
  .profile-handler {
    padding: 40px 25px;
  }
  .about-pt {
    padding-top: 100px;
  }
  .about-pt.desktop-pt {
    padding-top: 20px;
  }
  .token-box-s1 {
    bottom: -285px !important;
  }
  .payment-page {
    padding: 12px 12px 40px;
  }
  .payment-page h2 {
    font-size: 22px;
    line-height: 28px;
  }
  .card-payment-body {
    padding: 40px 30px;
  }
  .makeStyles-header80-5,
  .makeStyles-paper80-4 {
    max-width: 567px !important;
  }
  .makeStyles-paper80-4 {
    height: 100%;
  }
  .css-1e6y48t-MuiButtonBase-root-MuiButton-root.btn-neutral {
    height: 25px !important;
    min-width: 25px !important;
    border-radius: 4px;
  }
  .banner-social {
    padding: 0px 10px;
    margin: 0 10px;
  }
  .toktmln-item:before {
    height: 25px;
    width: 25px;
    border: 4px solid #dee2e6;
  }
  .thumbnail-slider-wrap .tab-btn-row .slick-track .slick-slide.slick-current {
    width: 35% !important;
  }
  .tab-btn {
    height: 30px;
    padding: 0 0 0 50px;
    font-size: 8px;
    display: flex;
    align-items: center;
  }
  .thumbnail-slider-wrap .tab-btn-row .slick-track {
    align-items: end;
    justify-content: center;
  }
  .thumbnail-slider-wrap
    .tab-btn-row
    .slick-track
    .slick-slide:nth-child(1)
    .tab-btn {
    padding-left: 13px;
  }
  .user-wraper {
    /* padding-top: 80px; */
  }
  .user-panel .user-blnc {
    margin-bottom: 15px;
  }
  .thumbnail-slider-wrap .accordion-slider .slick-prev::before,
  .thumbnail-slider-wrap .accordion-slider .slick-next::before {
    font-size: 12px !important;
  }
  .thumbnail-slider-wrap .accordion-slider .slick-prev,
  .thumbnail-slider-wrap .accordion-slider .slick-next {
    padding: 0px 29px;
    height: 28px;
  }
  .thumbnail-slider-wrap .accordion-slider.slick-slider .slick-prev {
    right: 14% !important;
  }
  .accordion-content p {
    font-size: 12px !important;
    line-height: 20px !important;
  }
  .user-panel p {
    font-size: 13px;
    line-height: 20px;
  }
  body {
    font-size: 12px;
  }
  .info-section {
    padding: 10px;
  }
  .single-timeline-area {
    padding-left: 80px;
  }
  .single-timeline-area .timeline-date {
    padding-right: 35px;
    width: 100px;
  }
  .single-timeline-area .timeline-date p {
    font-size: 11px;
  }
  .user-panel {
    padding: 25px;
  }
  .single-timeline-area .single-timeline-content .timeline-text p {
    font-size: 12px;
  }
  .timeline-w-75 {
    width: 100%;
  }
  .single-timeline-area .single-timeline-content {
    padding: 15px 15px 10px;
    border-radius: 4px;
    margin-top: 10px;
    flex-direction: column;
    /* align-items: center; */
  }
  .user-dropdown-balance {
    padding: 10px 18px 8px 18px;
  }
  .user-dropdown-btns {
    padding: 5px 0px;
  }
  .user-dropdown-balance h3 {
    font-size: 12px;
  }
  .user-panel {
    padding: 18px 20px;
  }
  .tranx-status .ti {
    height: 25px;
    width: 25px;
    line-height: 24px;
    font-size: 10px;
  }
  .data-table tbody td {
    font-size: 11px;
    line-height: 16px;
    padding: 12px 0;
  }
  .data-table tbody td div > span,
  .data-table tbody td > span {
    font-size: 13px;
  }
  .profile-img {
    width: 80px !important;
    height: 80px !important;
    margin-left: auto;
    margin-right: auto;
    margin-top: 35px;
  }
  .profile-handler {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
  .profile-handler .avater-container {
    width: 89%;
    margin: 0 auto 30px auto;
    padding-bottom: 30px;
  }
  .avatar-design {
    clip-path: polygon(49% 0, 100% 0, 100% 13%, 50% 40%, 0 13%, 0 0);
    height: 200px;
  }
  .btn-md.btn-grad {
    padding: 2px 14px;
    min-width: 100px;
  }
  .upload-btn2 svg {
    font-size: 11px;
  }
  .btn-md.btn-grad svg {
    width: 15px;
    height: 15px;
  }
  .upload-btn2.btn span {
    font-size: 11px;
    line-height: 20px;
  }
  .profile-label label {
    margin-bottom: 8px;
  }
  .profile-label,
  .cms-col-mb row.items-push > div {
    margin-bottom: 14px;
  }
  .profile-handler form input,
  .common-cms-pg.block-content div input {
    height: 12px !important;
    font-size: 11px !important;
  }
  .common-cms-pg.block-content .ico-dates-input .input-20 input {
    height: 20px !important;
  }
  .common-cms-pg.block-content .ico-dates-input .field-ico input {
    height: 13px !important;
  }
  .common-cms-pg.block-content .ico-dates-input input {
    height: 30px !important;
    font-size: 11px !important;
  }
  .w95 {
    margin-top: 35px !important;
  }
  .user-header-h5 {
    font-size: 18px;
    line-height: 22px;
    margin: 15px 13px 1px 13px;
  }
  .block-title span {
    font-size: 16px;
    line-height: 20px;
  }
  .common-cms-pg.block-content {
    padding: 20px 15px;
  }
  .table td span {
    font-size: 11px;
    line-height: 15px;
  }
  .table thead th {
    font-size: 14px;
    padding: 16px 20px;
  }
  .table td {
    padding: 9px 15px;
  }
  .header-search-input input {
    font-size: 12px !important;
  }
  .filter-div {
    align-items: center;
  }
  .range-bar > div {
    max-width: 90% !important;
  }
  .content-heading {
    margin-bottom: 5px;
    padding-top: 10px;
    font-size: 17px;
    line-height: 23px;
  }
  .ico-tabs .tab-icon svg {
    width: 17px;
    height: 17px;
  }
  .ico-tabs .tab-card {
    padding: 10px;
    font-size: 11px;
  }
  .transaction-report-graph .apexcharts-toolbar {
    top: 20px !important;
    left: 0;
    justify-content: flex-start;
  }
  .transaction-report-graph .apexcharts-toolbar div {
    font-size: 10px;
  }
  .email-msg-modal > div {
    height: 275px !important;
  }
  .email-form > .email-inner-form > div {
    width: 90% !important;
    height: 300px !important;
  }
  .email-form > header {
    width: 90% !important;
  }
  /* RCV/SPEND TOKENS */
  .rcv-tokens,
  .spend-tokens {
    padding: 35px;
  }
  .chat-box {
    bottom: 3rem;
  }
}
@media (max-width: 640px) {
  .whitelist-input > input {
    width: 55%;
  }
  .whitelist-input .btn-primary.address-btn {
    width: 100px;
  }
  .ico-tabs .px-tab {
    padding-left: 5px !important;
    padding-left: 5px !important;
  }
}
@media (max-width: 576px) {
  .invest-name{font-weight: 600;}
  .icnBox {
    /* top: -115px; */
    width: 150px;
  }
  .desktop-copyrights {
    display: none;
  }
  .mobile-none {
    display: none;
  }
  /* MOBILE-HOME */
  .mini-px {
    padding-left: 0;
  }
  .mobile-copyrights {
    display: block;
  }
  .user-header-h5 {
    font-size: 15px;
    margin: 11px 13px 1px;
  }
  .header-pb {
    padding-bottom: 35px;
  }
  .MuiOutlinedInput-adornedStart {
    padding-left: 0px !important;
  }
  .pagination-css ul li button {
    min-width: 25px;
    height: 25px;
  }
  .tranx-popup {
    padding: 10px 5px;
  }
  .email-form > .email-inner-form > div {
    height: 280px !important;
    /* padding: 0 15px; */
  }
  .tag-elipse {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 160px;
    height: 1.2em;
    white-space: nowrap;
  }
  .whitelist-input > input {
    width: 65%;
  }
  .whitelist-input {
    padding: 15px;
  }
  .white-container {
    margin: 15px;
  }
  .user-panel {
    padding: 14px 15px;
  }
  .input-item-label {
    font-size: 13px;
  }
  .user-panel p {
    font-size: 11px !important;
    line-height: 19px;
  }
  .user-sidebar-mobile {
    width: 286px;
  }
  .info-card-image {
    text-align: center;
  }
  .token-smartag {
    min-width: 195px;
    font-size: 11px;
    top: 25px;
  }
  .token-info {
    padding: 20px 10px 20px 65px;
  }
  .invest-amou {
    font-size: 12px;
    margin-bottom: 1px;
  }
  .invest-name {
    /* font-size: 15px; */
  }
  .info-section {
    border-radius: 5px;
    margin-bottom: 25px;
  }
  .tab-btn {
    word-break: break-word;
  }
  .tile-light {
    padding: 25px 15px 20px;
  }
  .thumbnail-slider-wrap .tab-btn-row .slick-track {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .thumbnail-slider-wrap .tab-btn-row .slick-track .slick-slide.slick-current,
  .thumbnail-slider-wrap .tab-btn-row .slick-track .slick-slide {
    width: 75% !important;
  }
  .thumbnail-slider-wrap
    .tab-btn-row
    .slick-track
    .slick-slide:not(:first-of-type) {
    margin-left: 0px;
  }
  .tab-btn {
    margin-bottom: 8px;
    padding: 0 0 0 13px;
  }
  .thumbnail-slider-wrap .accordion-slider.slick-slider .slick-prev {
    right: 15% !important;
  }
  .payment-calculator > div {
    padding: 10px 0;
  }
  .payment-summary-info span:last-child {
    font-size: 12px;
  }
  .badge-presale {
    height: 23px;
    padding: 3px 8px 3px 8px;
    margin-left: 4px;
    font-size: 10px;
    line-height: 17px;
    letter-spacing: 2px;
  }
  .user-panel-subtitle {
    font-size: 10px;
    margin-bottom: 3px;
  }
  .input-bordered {
    border-radius: 3px;
    border: 2px solid #d3e0f3;
    padding: 8px 10px;
    line-height: 17px;
    font-size: 12px;
    margin-bottom: 12px;
    width: 100% !important;
  }
  .payment-bonus-amount {
    font-size: 14px;
  }
  .payment-calculator-note {
    font-size: 11px;
    line-height: 16px;
  }
  .payment-summary {
    border-radius: 3px;
    padding: 13px 14px 0;
  }
  .btn {
    padding: 5px 15px;
    font-size: 10px;
    line-height: 15px;
  }
  .payment-summary-title {
    font-size: 13px;
    line-height: 18px;
  }
  .payment-summary-info span:last-child,
  .payment-summary-amount {
    font-size: 10px;
  }
  .payment-summary-info span:first-child:not(.payment-summary-amount) {
    font-size: 11px;
    top: -1px;
  }
  .header-nav-toggle {
    height: 20px;
  }
  .header-dashboard-toggle {
    margin-top: 13px;
  }
  .user-nav li a,
  .user-nav li,
  .user-nav li span {
    font-size: 11px;
    line-height: 18px;
  }
  .topbar-action-link a {
    font-size: 12px;
    line-height: 25px;
  }
  .topbar-action-link .ti,
  .topbar-action-link .icofont,
  .topbar-action-link [class*="fa-"] {
    font-size: 15px;
    margin-right: 5px;
    line-height: 23px;
  }
  .single-timeline-area .single-timeline-content .timeline-text p {
    font-size: 11px;
    line-height: 16px;
  }
  .single-timeline-area .timeline-date::before {
    width: 8px;
    height: 8px;
    right: 27px;
  }
  .nav-tabs-line .nav-link {
    font-size: 12px;
    line-height: 20px;
  }
  .upload-btn div.bg-primary {
    padding: 8px 2px;
    height: 32px;
  }
  .upload-btn svg {
    font-size: 12px;
  }
  .token-countdown-wrapper,
  .token-status-s1 {
    padding: 15px 10px;
  }
  .ContainerTitle {
    top: 60px !important;
  }
  .btn-play-icon-s2 {
    height: 25px;
    width: 15px;
  }
  .partner-logo-s2 img,
  .partner-logo-s3 img {
    max-height: 35px;
  }
  .feature-s10-left {
    margin-right: 70px;
    margin-right: 0px;
  }
  .title-s4 {
    font-size: 25px;
    line-height: 29px;
    padding-bottom: 5px;
  }
  .title-sm {
    font-size: 15px;
    margin-bottom: 5px !important;
  }
  .section-head:not([class*="pdb"]) {
    padding-bottom: 15px;
  }
  .section-b,
  .section {
    padding-bottom: 35px;
  }
  .section-t,
  .section {
    padding-top: 35px;
  }
  .nk-block-img-s2 img {
    max-width: 280px;
    width: 100%;
  }
  .ui-shape:before {
    left: 0;
    top: 3%;
  }
  .ui-shape-header:after {
    right: -15%;
    bottom: 32%;
  }
  .toktmln-item:before {
    height: 20px;
    width: 20px;
    border: 3px solid #dee2e6;
  }
  .token-box-s1 {
    bottom: -170px !important;
  }
  .about-pt {
    padding-top: 15px;
  }
  section.about-section {
    padding-top: 100px;
  }
  /* ******************** */
  .wistia_responsive_padding {
    padding: 56.25% 0 0 0;
    position: relative;
  }
  .wistia_responsive_wrapper {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .wistia_embed {
    display: inline-block;
    height: 100%;
    position: relative;
    width: 100%;
  }
  /* ********* */
  .profile-handler .profile-form {
    width: 100%;
  }
  .block.block-rounded .block-header {
    display: flex;
    align-items: center;
  }
  .block.block-rounded .block-header svg {
    width: 12px;
  }
  .table-bar-reverse {
    flex-direction: row-reverse;
  }
  .wrap-search-input {
    position: absolute;
    bottom: 6px;
    right: 20px;
    left: 20px;
  }
  .search-wrap {
    width: 100% !important;
  }
  .search-w {
    width: auto;
  }
  .search-wrap,
  .wrap-search-input {
    width: auto !important;
  }
  /* .block.block-rounded .block-header {
  padding-bottom:25px;
} */
  /* #header-search-wrapper-sub-div{
  margin-right:-50px;
} */
  .apexcharts-legend {
    justify-content: flex-start !important;
  }
  .apexcharts-legend > div {
    margin: 2px 25px !important;
  }
  /* CRYPTO-DOCUMENTS */
  .crypto-whitepaper {
    flex-direction: column;
  }
  .crypto-center {
    flex-direction: column;
  }
  .crypto-whitepaper .crypto-center > div {
    margin-right: 0px;
    margin-bottom: 25px;
  }
  /* CHATBOX */
  .chat-box {
    right: 10px;
  }
  .chat-box .chat-box-style {
    height: 45px;
    width: 45px;
  }
  .chat-box .chat-box-style svg {
    width: 20px;
  }
  .circle-tick li::before {
    width: 15px;
    height: 15px;
    font-size: 10px;
  }
  .circle-tick li {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 7px;
  }
  .detail-title {
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 13px;
  }
  .roadmap-header,
  .roadmap-body {
    padding: 13px;
  }
  .section-head.team-head {
    margin-bottom: 30px !important;
  }
  .rcv-tokens,
  .spend-tokens {
    padding: 25px;
  }
  /* CONNECT WALLET BUTTON */
  .connect-wallet > div > button:first-child {
    font-size: 12px !important;
    line-height: 20px !important;
    height: auto !important;
  }
  .connect-wallet > div {
    align-items: center !important;
  }
  .mobileView .content-header , .mini-px .content-header{
    padding: 10px 10px;
    flex-direction: column;
    height: auto;
    align-items: flex-start;
  }
  .content-header.site-logo-in-mobile {
    height: 6.375rem;
  }
}
@media (max-width: 575.98px) {
  .email-formating ul li{flex-direction: column;}
  .email-formating ul li span{font-size: 12px;}
  h2 {
    font-size: 21px !important;
    line-height: 25px;
  }
  p,
  body {
    font-size: 14px;
    line-height: 24px;
  }
  .ico-tabs {
    position: relative;
  }
  .scroll-btn .css-orq8zk .MuiTabs-scrollButtons,
  .css-orq8zk .MuiTabs-scrollButtons {
    display: block !important;
    margin: auto;
  }
  .block.block-rounded > .block-header {
    justify-content: flex-start;
    align-items: center;
    /* flex-direction: column; */
  }
  .nav-tabs-icon {
    height: 30px;
    width: 30px;
  }
  .nav-tabs-icon img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .nav-tabs-bordered .nav-link {
    padding: 10px 0 8px;
    font-size: 9px;
    line-height: 13px;
  }
  .common-cms-pg.block-content .row {
    --bs-gutter-x: 0px !important;
  }
  .block-content.block-content-full {
    padding: 20px 5px;
  }
  .card-payment-body-details .detail input {
    border-radius: 5px !important;
  }
  .makeStyles-header80-5,
  .makeStyles-paper80-4 {
    max-width: 100% !important;
  }
  .sun-editor .se-btn {
    max-height: 25px;
  }
  .sun-editor .se-svg,
  .sun-editor button > svg {
    max-width: 12px;
    max-height: 12px;
  }
  .MuiInputBase-input {
    height: 16px;
    font-size: 13px;
  }
  .container {
    max-width: 100% !important;
    padding: 0px 20px;
  }
  .card-payment-bg {
    height: 70px;
    border-radius: 13px 13px 0px 0px;
  }
  .card-payment .img-logo-round {
    width: 90px;
    height: 90px;
  }
  .card-payment .img-logo-round a img {
    width: 23px;
  }
  .card-payment-body-details .detail {
    flex-direction: column;
  }
  .card-payment-body {
    padding: 40px 12px;
  }
  .card-payment-body-details .detail p img {
    margin-right: 7px;
    width: 18px;
  }
  .card-payment-body-details .detail p:nth-child(1) {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    font-size: 15px;
  }
  .card-payment-body-details .detail p:nth-child(2) {
    font-size: 14px;
  }
  .avater-img > img {
    width: 60px;
    height: 60px;
  }
  .avater-container {
    padding: 15px 10px;
  }
  .avatar-info {
    width: calc(100% - 80px);
  }
  .ico-tabs .tab-card {
    width: 220px !important;
  }
  .w45 {
    width: 100%;
  }
  .mt-btn {
    margin-top: 5px;
  }
  .tag-filter {
    width: 100% !important;
  }
  .range-bar > div {
    width: 100% !important;
  }
  .range-bar span {
    max-width: 100%;
  }
  .betts-img {
    width: auto;
  }
  .user-wraper {
    padding-top: 140px;
}
}
@media (max-width: 485px) {
  .thumbnail-slider-wrap .accordion-slider.slick-slider .slick-prev {
    right: 65px !important;
  }
  /* ROADM */
  .roadmap-plan {
    border-left: 0px solid #fff;
  }
  .ico-roadmap {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  }
  .FlipClock .flipUnitContainer .ContainerTitle {
    font-size: 7px;
  }
}
@media (max-width: 480px) {
  .tile-item .your-contribution{justify-content: inherit !important;}
  .whitelist-input > input {
    width: 100%;
    margin-bottom: 5px;
  }
  .whitelist-input .btn-primary.address-btn {
    border-radius: 5px;
  }
  .tab-btn {
    font-size: 8px;
  }
  .thumbnail-slider-wrap .accordion-slider .slick-prev::before,
  .thumbnail-slider-wrap .accordion-slider .slick-next::before {
    font-size: 10px !important;
    line-height: 12px;
  }
  /* .about-pt {
  padding-top: 220px;
} */
  .transaction-modal .modal-content {
    width: 95% !important;
    margin: 0 auto;
  }
  .modal-open .modal {
    justify-content: center;
  }
  .team-popup-main > div {
    right: 0 !important;
    left: 0 !important;
    width: 90% !important;
  }
  .ico-tabs .tab-card {
    width: 100% !important;
  }
  .ico-tabs .tab-card:not(:last-child) {
    margin-right: 63px;
  }
  .chat-box .chat-box-style {
    height: 35px;
    width: 35px;
  }
}
@media (max-width: 400px) {
  .thumbnail-slider-wrap .accordion-slider.slick-slider .slick-prev {
    right: 43px !important;
  }
  .thumbnail-slider-wrap .accordion-slider .slick-prev,
  .thumbnail-slider-wrap .accordion-slider .slick-next {
    padding: 0px 19px;
    height: 22px;
  }
  .accordion-content p,
  .user-panel p {
    font-size: 10px !important;
    line-height: 16px !important;
  }
  .token-box-girth {
    width: 70%;
    margin: 0 auto;
  }
}
/*  */
.tooltip-user {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip-user .tooltiptext-user {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 1s;
}
.tooltip-user:hover .tooltiptext-user {
  visibility: visible;
  opacity: 1;
}

.your-contribution .text-dark {
  padding: 0 6px;
}

/* .Toastify__toast-icon{
  display: none !important;
}
.Toastify__progress-bar{
  display: none !important;
} */
.received_amount{
  color: green !important;
}
.expected_amount{
  color: #ff6c3f !important;
}
.border-top{
  border-top: 0px solid #dee2e6 !important;
}
/* .Toastify__toast{
  padding: 2px !important;
  border-radius: 3px 25px 3px 25px !important;
  box-shadow: 7px 6px 2px #dfdfdf !important;
  border: 2px solid #ccc !important;
} */
button.btn-close{
  position: absolute !important;
    right: 18px !important;
}
.auto-height-modal .modal-content{
  height: auto !important;
  width: 100%;
  max-width: 620px;
}